.page-blog {
	.wrapper {
		padding-bottom: 3rem;
		.tease{
			border: 3px solid $brand-black;
			background-color: $brand-gray;
				max-width: 350px;
				padding: .4rem;
				height: 100%;
				position:relative;


			img	{
				width: 100%;
    height: 232px;
    object-fit: cover;
			}

			&-content {
				padding: 1.1rem;
				padding-bottom: 4.5rem;

				h2 {
					font-size: 1.5rem;
				}

				.read-more {
					margin-top: 1rem;
					display: none;
					@include btn;
					text-align: center;
					padding: .5rem;
				}

				&-bottom {
					    transform: translate(50%, 0%);
    bottom: 1rem;
    left: 0;
    z-index: 3;
    position: absolute;

					}

				}
			

			&-category {
					    position: relative;
					    margin-top: -26px;


				&-inner {
					    background: $brand-pink;
					    padding: 0.3rem;
					    color: white;
				}
			}	
		}
	}
}