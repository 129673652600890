// Iconfont
// @import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

//// Fontface
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

body{
  font-family: 'Lato', sans-serif;
  color: $brand-black;
}
*{
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.xbold {
  font-weight: 800;
}

.xxbold {
  font-weight: 900;
}


.display3 {
  font-style: normal;
  font-size: 64px;
  line-height: 78px;
 
}.display1 {
  font-weight: 800;
font-size: 144px;
line-height: 176px;
@include media-breakpoint-down(md) {
  font-size: rem(76);
  line-height: 176px;
}
}

.p {
  font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 1rem;
line-height: 267.4%;
/* or 43px */

letter-spacing: 0.1em;
}

//Colors
.gray {
  color: $brand-gray;
}

.pink {
  color: $brand-pink;
}

.black {
  color: $brand-black;
}

// AVG word width h3 display3
$avw: 321;
$avw2: 535;
.pink-stripe {
  position: relative;
  font-weight: 800;
  &::after {
    content: "";
    position: absolute;
    width: #{$avw * 0.7}px;
    height: 55px;
    background: $brand-pink;
    right: 33px;
    top: 43px;
    z-index: -1;
  }

  &.pink-stripe-two {
      &::after {

    width: #{$avw2  * 0.8}px;
    }
  }

  &.display1 {
      &::after {

      bottom:0;
      top: unset;
      height: 77px;
    }
  }
}