html {
  scroll-behavior: smooth;
  /******DEBUG*******/
  // *{
  //  outline: 2px dashed green;
  // }
  // div {
  //   outline-color: blue;
  // }
  // h1,h2,h3,h4,h5,h6,p,span {
  //   outline-color: red;
  // }

}
body {
  font-family: 'Montserrat', 'sans-serif';
  overflow-x: hidden;
  background: $brand-gray;
}

.pink-line {
  border-top: 0.0625rem solid $brand-pink;
}

.bg-pink {
  background-color: $brand-pink !important;
}

.bg-black {
  background-color: $brand-black !important;
}

.transition {
  width: 0%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  background: black;
}

.loading-screen {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background-color: $brand-black;
  width: 100%;
  height: 100%;
  transform: scaleY(0) skewX(0);
  transform-origin: top left;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

#cookie-notice {
  font-family: 'Montserrat', 'sans-serif';
}

#scrollContent {
  overflow: hidden;
}



/*Scrollbar*/
/* width */
::-webkit-scrollbar {
  width: 6px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
    background: #e7e7e7;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #bfbfbf;
    border-radius: 10px;
}



#preloader {
  position: fixed;
  background: $brand-black;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  flex-direction: column;
  //display: none;


  svg {
    margin-bottom: 2rem;
    max-width: 100px;
        overflow: visible;

    #pinkDotPath {
      // animation: spin 1s infinite linear;
      transform-origin: -50px
    }
  }

  .logo-wrapper {
    position: relative;
  }

  .fillbar {
    width: 0%;
    border: 1px solid $brand-pink;
    height: 3px;
    background: $brand-pink;
    // bottom:  49px;
    left: 39px;
    animation: fillbar-fill 2s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    &-wrapper{
      position:relative;
    width: 60%;

    }
  }

  .spinner {
    width: 80px;
    height: 80px;

    border: 2px solid #f3f3f3;
    border-top: 3px solid black;
    border-radius: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes fillbar-fill {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }


}