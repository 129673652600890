.page-projekt {
    #project {
        position: relative;
    }
    #shape {
        width: 269px;
        height: 269px;
        background: $brand-pink;
        opacity: 0.5;
        position: absolute;
        top: -3rem;
        left: -9rem;
        border-radius: 50%;
        z-index: 1;
    }

    #projectTitle {
        position: absolute;
       
        left: 10%;
        top: 30%;
        max-width: 100%;
        z-index: -1;
       
        span {
            color: rgba(255,255,255,1);
            $colr: rgba(0, 0, 0, 0.19);
            text-shadow: -1px 0 $colr, 0 1px $colr, 1px 0 $colr, 0 -1px $colr;
            font-weight: bold;
            font-size: 18vw;
            line-height: 224px;
            white-space: nowrap;
        }
    }

    
}
