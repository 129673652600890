/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
/* line 2, node_modules/bootstrap/scss/_root.scss */
:root {
  --blue: #007bff;
  --indigo: #414042;
  --purple: #DEE0ED;
  --pink: #e83e8c;
  --red: #E60000;
  --orange: #fd7e14;
  --yellow: #FFB600;
  --green: #24F300;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #D1D3D4;
  --gray-dark: #6c757d;
  --brand: #2B2024;
  --purple-light: #F2F4FF;
  --primary: #2B2024;
  --secondary: #fff;
  --success: #24F300;
  --info: #17a2b8;
  --warning: #FFB600;
  --danger: #FD0054;
  --light: #FBFBFB;
  --dark: #2B2024;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

/* line 19, node_modules/bootstrap/scss/_reboot.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 25, node_modules/bootstrap/scss/_reboot.scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* line 35, node_modules/bootstrap/scss/_reboot.scss */
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

/* line 46, node_modules/bootstrap/scss/_reboot.scss */
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

/* line 66, node_modules/bootstrap/scss/_reboot.scss */
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

/* line 76, node_modules/bootstrap/scss/_reboot.scss */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* line 92, node_modules/bootstrap/scss/_reboot.scss */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

/* line 101, node_modules/bootstrap/scss/_reboot.scss */
p {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 114, node_modules/bootstrap/scss/_reboot.scss */
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

/* line 123, node_modules/bootstrap/scss/_reboot.scss */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

/* line 129, node_modules/bootstrap/scss/_reboot.scss */
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

/* line 136, node_modules/bootstrap/scss/_reboot.scss */
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

/* line 143, node_modules/bootstrap/scss/_reboot.scss */
dt {
  font-weight: 700; }

/* line 147, node_modules/bootstrap/scss/_reboot.scss */
dd {
  margin-bottom: .5rem;
  margin-left: 0; }

/* line 152, node_modules/bootstrap/scss/_reboot.scss */
blockquote {
  margin: 0 0 1rem; }

/* line 156, node_modules/bootstrap/scss/_reboot.scss */
b,
strong {
  font-weight: bolder; }

/* line 161, node_modules/bootstrap/scss/_reboot.scss */
small {
  font-size: 80%; }

/* line 170, node_modules/bootstrap/scss/_reboot.scss */
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

/* line 178, node_modules/bootstrap/scss/_reboot.scss */
sub {
  bottom: -.25em; }

/* line 179, node_modules/bootstrap/scss/_reboot.scss */
sup {
  top: -.5em; }

/* line 186, node_modules/bootstrap/scss/_reboot.scss */
a {
  color: #414042;
  text-decoration: none;
  background-color: transparent; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:hover {
    color: #1b1a1b;
    text-decoration: underline; }

/* line 202, node_modules/bootstrap/scss/_reboot.scss */
a:not([href]) {
  color: inherit;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

/* line 217, node_modules/bootstrap/scss/_reboot.scss */
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

/* line 225, node_modules/bootstrap/scss/_reboot.scss */
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

/* line 242, node_modules/bootstrap/scss/_reboot.scss */
figure {
  margin: 0 0 1rem; }

/* line 252, node_modules/bootstrap/scss/_reboot.scss */
img {
  vertical-align: middle;
  border-style: none; }

/* line 257, node_modules/bootstrap/scss/_reboot.scss */
svg {
  overflow: hidden;
  vertical-align: middle; }

/* line 269, node_modules/bootstrap/scss/_reboot.scss */
table {
  border-collapse: collapse; }

/* line 273, node_modules/bootstrap/scss/_reboot.scss */
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

/* line 281, node_modules/bootstrap/scss/_reboot.scss */
th {
  text-align: inherit; }

/* line 292, node_modules/bootstrap/scss/_reboot.scss */
label {
  display: inline-block;
  margin-bottom: 0.5rem; }

/* line 301, node_modules/bootstrap/scss/_reboot.scss */
button {
  border-radius: 0; }

/* line 310, node_modules/bootstrap/scss/_reboot.scss */
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

/* line 315, node_modules/bootstrap/scss/_reboot.scss */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/* line 326, node_modules/bootstrap/scss/_reboot.scss */
button,
input {
  overflow: visible; }

/* line 331, node_modules/bootstrap/scss/_reboot.scss */
button,
select {
  text-transform: none; }

/* line 339, node_modules/bootstrap/scss/_reboot.scss */
[role="button"] {
  cursor: pointer; }

/* line 346, node_modules/bootstrap/scss/_reboot.scss */
select {
  word-wrap: normal; }

/* line 354, node_modules/bootstrap/scss/_reboot.scss */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/* line 367, node_modules/bootstrap/scss/_reboot.scss */
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

/* line 374, node_modules/bootstrap/scss/_reboot.scss */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

/* line 382, node_modules/bootstrap/scss/_reboot.scss */
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

/* line 389, node_modules/bootstrap/scss/_reboot.scss */
textarea {
  overflow: auto;
  resize: vertical; }

/* line 395, node_modules/bootstrap/scss/_reboot.scss */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

/* line 410, node_modules/bootstrap/scss/_reboot.scss */
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    /* line 410, node_modules/bootstrap/scss/_reboot.scss */
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

/* line 422, node_modules/bootstrap/scss/_reboot.scss */
progress {
  vertical-align: baseline; }

/* line 427, node_modules/bootstrap/scss/_reboot.scss */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* line 432, node_modules/bootstrap/scss/_reboot.scss */
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

/* line 445, node_modules/bootstrap/scss/_reboot.scss */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* line 454, node_modules/bootstrap/scss/_reboot.scss */
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* line 463, node_modules/bootstrap/scss/_reboot.scss */
output {
  display: inline-block; }

/* line 467, node_modules/bootstrap/scss/_reboot.scss */
summary {
  display: list-item;
  cursor: pointer; }

/* line 472, node_modules/bootstrap/scss/_reboot.scss */
template {
  display: none; }

/* line 478, node_modules/bootstrap/scss/_reboot.scss */
[hidden] {
  display: none !important; }

/* line 7, node_modules/bootstrap/scss/_type.scss */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

/* line 16, node_modules/bootstrap/scss/_type.scss */
h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    /* line 16, node_modules/bootstrap/scss/_type.scss */
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

/* line 17, node_modules/bootstrap/scss/_type.scss */
h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    /* line 17, node_modules/bootstrap/scss/_type.scss */
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

/* line 18, node_modules/bootstrap/scss/_type.scss */
h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    /* line 18, node_modules/bootstrap/scss/_type.scss */
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

/* line 19, node_modules/bootstrap/scss/_type.scss */
h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    /* line 19, node_modules/bootstrap/scss/_type.scss */
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

/* line 20, node_modules/bootstrap/scss/_type.scss */
h5, .h5 {
  font-size: 1.25rem; }

/* line 21, node_modules/bootstrap/scss/_type.scss */
h6, .h6 {
  font-size: 1rem; }

/* line 23, node_modules/bootstrap/scss/_type.scss */
.lead {
  font-size: 1.25rem;
  font-weight: 300; }

/* line 29, node_modules/bootstrap/scss/_type.scss */
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    /* line 29, node_modules/bootstrap/scss/_type.scss */
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

/* line 34, node_modules/bootstrap/scss/_type.scss */
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    /* line 34, node_modules/bootstrap/scss/_type.scss */
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

/* line 39, node_modules/bootstrap/scss/_type.scss */
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    /* line 39, node_modules/bootstrap/scss/_type.scss */
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

/* line 44, node_modules/bootstrap/scss/_type.scss */
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    /* line 44, node_modules/bootstrap/scss/_type.scss */
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

/* line 55, node_modules/bootstrap/scss/_type.scss */
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* line 67, node_modules/bootstrap/scss/_type.scss */
small,
.small {
  font-size: 80%;
  font-weight: 400; }

/* line 73, node_modules/bootstrap/scss/_type.scss */
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

/* line 84, node_modules/bootstrap/scss/_type.scss */
.list-unstyled {
  padding-left: 0;
  list-style: none; }

/* line 89, node_modules/bootstrap/scss/_type.scss */
.list-inline {
  padding-left: 0;
  list-style: none; }

/* line 92, node_modules/bootstrap/scss/_type.scss */
.list-inline-item {
  display: inline-block; }
  /* line 95, node_modules/bootstrap/scss/_type.scss */
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

/* line 106, node_modules/bootstrap/scss/_type.scss */
.initialism {
  font-size: 90%;
  text-transform: uppercase; }

/* line 112, node_modules/bootstrap/scss/_type.scss */
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

/* line 117, node_modules/bootstrap/scss/_type.scss */
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  /* line 122, node_modules/bootstrap/scss/_type.scss */
  .blockquote-footer::before {
    content: "\2014\00A0"; }

/* line 8, node_modules/bootstrap/scss/_images.scss */
.img-fluid {
  max-width: 100%;
  height: auto; }

/* line 14, node_modules/bootstrap/scss/_images.scss */
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E6E7E8;
  border-radius: 0.1875rem;
  max-width: 100%;
  height: auto; }

/* line 29, node_modules/bootstrap/scss/_images.scss */
.figure {
  display: inline-block; }

/* line 34, node_modules/bootstrap/scss/_images.scss */
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

/* line 39, node_modules/bootstrap/scss/_images.scss */
.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/* line 2, node_modules/bootstrap/scss/_code.scss */
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  /* line 8, node_modules/bootstrap/scss/_code.scss */
  a > code {
    color: inherit; }

/* line 14, node_modules/bootstrap/scss/_code.scss */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  /* line 22, node_modules/bootstrap/scss/_code.scss */
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

/* line 31, node_modules/bootstrap/scss/_code.scss */
pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  /* line 37, node_modules/bootstrap/scss/_code.scss */
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

/* line 45, node_modules/bootstrap/scss/_code.scss */
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/* line 7, node_modules/bootstrap/scss/_grid.scss */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    /* line 7, node_modules/bootstrap/scss/_grid.scss */
    .container {
      max-width: 1140px; } }

/* line 13, node_modules/bootstrap/scss/_grid.scss */
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  /* line 24, node_modules/bootstrap/scss/_grid.scss */
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

/* line 53, node_modules/bootstrap/scss/_grid.scss */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

/* line 59, node_modules/bootstrap/scss/_grid.scss */
.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  /* line 63, node_modules/bootstrap/scss/_grid.scss */
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

/* line 8, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

/* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

/* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

/* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

/* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-first {
  order: -1; }

/* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-last {
  order: 13; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-0 {
  order: 0; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-1 {
  order: 1; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-2 {
  order: 2; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-3 {
  order: 3; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-4 {
  order: 4; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-5 {
  order: 5; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-6 {
  order: 6; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-7 {
  order: 7; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-8 {
  order: 8; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-9 {
  order: 9; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-10 {
  order: 10; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-11 {
  order: 11; }

/* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.order-12 {
  order: 12; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-1 {
  margin-left: 8.33333%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-2 {
  margin-left: 16.66667%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-3 {
  margin-left: 25%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-4 {
  margin-left: 33.33333%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-5 {
  margin-left: 41.66667%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-6 {
  margin-left: 50%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-7 {
  margin-left: 58.33333%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-8 {
  margin-left: 66.66667%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-9 {
  margin-left: 75%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-10 {
  margin-left: 83.33333%; }

/* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-first {
    order: -1; }
  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-last {
    order: 13; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-0 {
    order: 0; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-1 {
    order: 1; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-2 {
    order: 2; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-3 {
    order: 3; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-4 {
    order: 4; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-5 {
    order: 5; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-6 {
    order: 6; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-7 {
    order: 7; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-8 {
    order: 8; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-9 {
    order: 9; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-10 {
    order: 10; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-11 {
    order: 11; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-sm-12 {
    order: 12; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-0 {
    margin-left: 0; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-1 {
    margin-left: 8.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-2 {
    margin-left: 16.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-3 {
    margin-left: 25%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-4 {
    margin-left: 33.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-5 {
    margin-left: 41.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-6 {
    margin-left: 50%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-7 {
    margin-left: 58.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-8 {
    margin-left: 66.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-9 {
    margin-left: 75%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-10 {
    margin-left: 83.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-first {
    order: -1; }
  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-last {
    order: 13; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-0 {
    order: 0; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-1 {
    order: 1; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-2 {
    order: 2; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-3 {
    order: 3; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-4 {
    order: 4; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-5 {
    order: 5; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-6 {
    order: 6; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-7 {
    order: 7; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-8 {
    order: 8; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-9 {
    order: 9; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-10 {
    order: 10; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-11 {
    order: 11; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-md-12 {
    order: 12; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-0 {
    margin-left: 0; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-1 {
    margin-left: 8.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-2 {
    margin-left: 16.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-3 {
    margin-left: 25%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-4 {
    margin-left: 33.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-5 {
    margin-left: 41.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-6 {
    margin-left: 50%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-7 {
    margin-left: 58.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-8 {
    margin-left: 66.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-9 {
    margin-left: 75%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-10 {
    margin-left: 83.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-first {
    order: -1; }
  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-last {
    order: 13; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-0 {
    order: 0; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-1 {
    order: 1; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-2 {
    order: 2; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-3 {
    order: 3; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-4 {
    order: 4; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-5 {
    order: 5; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-6 {
    order: 6; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-7 {
    order: 7; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-8 {
    order: 8; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-9 {
    order: 9; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-10 {
    order: 10; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-11 {
    order: 11; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-lg-12 {
    order: 12; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-0 {
    margin-left: 0; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-1 {
    margin-left: 8.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-2 {
    margin-left: 16.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-3 {
    margin-left: 25%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-4 {
    margin-left: 33.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-5 {
    margin-left: 41.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-6 {
    margin-left: 50%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-7 {
    margin-left: 58.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-8 {
    margin-left: 66.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-9 {
    margin-left: 75%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-10 {
    margin-left: 83.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  /* line 34, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  /* line 65, node_modules/bootstrap/scss/mixins/_grid.scss */
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 49, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  /* line 55, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  /* line 61, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-first {
    order: -1; }
  /* line 63, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-last {
    order: 13; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-0 {
    order: 0; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-1 {
    order: 1; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-2 {
    order: 2; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-3 {
    order: 3; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-4 {
    order: 4; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-5 {
    order: 5; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-6 {
    order: 6; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-7 {
    order: 7; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-8 {
    order: 8; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-9 {
    order: 9; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-10 {
    order: 10; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-11 {
    order: 11; }
  /* line 66, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .order-xl-12 {
    order: 12; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-0 {
    margin-left: 0; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-1 {
    margin-left: 8.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-2 {
    margin-left: 16.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-3 {
    margin-left: 25%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-4 {
    margin-left: 33.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-5 {
    margin-left: 41.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-6 {
    margin-left: 50%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-7 {
    margin-left: 58.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-8 {
    margin-left: 66.66667%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-9 {
    margin-left: 75%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-10 {
    margin-left: 83.33333%; }
  /* line 73, node_modules/bootstrap/scss/mixins/_grid-framework.scss */
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* line 5, node_modules/bootstrap/scss/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  /* line 11, node_modules/bootstrap/scss/_tables.scss */
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #E6E7E8; }
  /* line 18, node_modules/bootstrap/scss/_tables.scss */
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #E6E7E8; }
  /* line 23, node_modules/bootstrap/scss/_tables.scss */
  .table tbody + tbody {
    border-top: 2px solid #E6E7E8; }

/* line 34, node_modules/bootstrap/scss/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.3rem; }

/* line 45, node_modules/bootstrap/scss/_tables.scss */
.table-bordered {
  border: 1px solid #E6E7E8; }
  /* line 48, node_modules/bootstrap/scss/_tables.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E6E7E8; }
  /* line 54, node_modules/bootstrap/scss/_tables.scss */
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

/* line 62, node_modules/bootstrap/scss/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

/* line 75, node_modules/bootstrap/scss/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4c1c2; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #918b8d; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-primary:hover {
  background-color: #b8b4b5; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b8b4b5; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c2fcb8; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8df97a; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-success:hover {
  background-color: #adfba0; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #adfba0; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-info:hover {
  background-color: #abdde5; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebb8; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffd97a; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-warning:hover {
  background-color: #ffe49f; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe49f; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #feb8cf; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fe7aa6; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-danger:hover {
  background-color: #fe9fbe; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fe9fbe; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fdfdfd; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c1c2; }

/* line 14, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #918b8d; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-dark:hover {
  background-color: #b8b4b5; }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b8b4b5; }

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  /* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

/* line 114, node_modules/bootstrap/scss/_tables.scss */
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

/* line 122, node_modules/bootstrap/scss/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #F1F2F2;
  border-color: #E6E7E8; }

/* line 130, node_modules/bootstrap/scss/_tables.scss */
.table-dark {
  color: #fff;
  background-color: #343a40; }
  /* line 134, node_modules/bootstrap/scss/_tables.scss */
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  /* line 140, node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-bordered {
    border: 0; }
  /* line 145, node_modules/bootstrap/scss/_tables.scss */
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    /* line 179, node_modules/bootstrap/scss/_tables.scss */
    .table-responsive-xl > .table-bordered {
      border: 0; } }

/* line 171, node_modules/bootstrap/scss/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive > .table-bordered {
    border: 0; }

/* line 7, node_modules/bootstrap/scss/_forms.scss */
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.75rem + 2px);
  padding: 0.875rem 1.5625rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #414042;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E6E7E8;
  border-radius: 0.1875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, node_modules/bootstrap/scss/_forms.scss */
    .form-control {
      transition: none; } }
  /* line 28, node_modules/bootstrap/scss/_forms.scss */
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  /* line 34, node_modules/bootstrap/scss/_forms.scss */
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #414042; }
  /* line 14, node_modules/bootstrap/scss/mixins/_forms.scss */
  .form-control:focus {
    color: #414042;
    background-color: #fff;
    border-color: #745661;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
  /* line 43, node_modules/bootstrap/scss/_forms.scss */
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  /* line 54, node_modules/bootstrap/scss/_forms.scss */
  .form-control:disabled, .form-control[readonly] {
    background-color: #F1F2F2;
    opacity: 1; }

/* line 66, node_modules/bootstrap/scss/_forms.scss */
input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

/* line 72, node_modules/bootstrap/scss/_forms.scss */
select.form-control:focus::-ms-value {
  color: #414042;
  background-color: #fff; }

/* line 84, node_modules/bootstrap/scss/_forms.scss */
.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

/* line 97, node_modules/bootstrap/scss/_forms.scss */
.col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

/* line 105, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 112, node_modules/bootstrap/scss/_forms.scss */
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 125, node_modules/bootstrap/scss/_forms.scss */
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.875rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  /* line 137, node_modules/bootstrap/scss/_forms.scss */
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

/* line 152, node_modules/bootstrap/scss/_forms.scss */
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 160, node_modules/bootstrap/scss/_forms.scss */
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 170, node_modules/bootstrap/scss/_forms.scss */
select.form-control[size], select.form-control[multiple] {
  height: auto; }

/* line 176, node_modules/bootstrap/scss/_forms.scss */
textarea.form-control {
  height: auto; }

/* line 185, node_modules/bootstrap/scss/_forms.scss */
.form-group {
  margin-bottom: 1rem; }

/* line 189, node_modules/bootstrap/scss/_forms.scss */
.form-text {
  display: block;
  margin-top: 0.25rem; }

/* line 199, node_modules/bootstrap/scss/_forms.scss */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  /* line 205, node_modules/bootstrap/scss/_forms.scss */
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

/* line 217, node_modules/bootstrap/scss/_forms.scss */
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

/* line 223, node_modules/bootstrap/scss/_forms.scss */
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  /* line 229, node_modules/bootstrap/scss/_forms.scss */
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

/* line 235, node_modules/bootstrap/scss/_forms.scss */
.form-check-label {
  margin-bottom: 0; }

/* line 239, node_modules/bootstrap/scss/_forms.scss */
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  /* line 246, node_modules/bootstrap/scss/_forms.scss */
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #24F300; }

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(36, 243, 0, 0.9);
  border-radius: 0.1875rem; }

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #24F300;
  padding-right: calc(1.5em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2324F300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.4375rem) center;
  background-size: calc(0.75em + 0.875rem) calc(0.75em + 0.875rem); }
  /* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #24F300;
    box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.25); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.75rem);
  background-position: top calc(0.375em + 0.4375rem) right calc(0.375em + 0.4375rem); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #24F300;
  padding-right: calc((1em + 1.75rem) * 3 / 4 + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2324F300' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.5625rem/calc(0.75em + 0.875rem) calc(0.75em + 0.875rem); }
  /* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #24F300;
    box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.25); }

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #24F300; }

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #24F300; }
  /* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #24F300; }

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #47ff27;
  background-color: #47ff27; }

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.25); }

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #24F300; }

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #24F300; }

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #24F300;
  box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.25); }

/* line 45, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FD0054; }

/* line 53, node_modules/bootstrap/scss/mixins/_forms.scss */
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(253, 0, 84, 0.9);
  border-radius: 0.1875rem; }

/* line 69, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FD0054;
  padding-right: calc(1.5em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FD0054' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FD0054' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.4375rem) center;
  background-size: calc(0.75em + 0.875rem) calc(0.75em + 0.875rem); }
  /* line 87, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #FD0054;
    box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.25); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.75rem);
  background-position: top calc(0.375em + 0.4375rem) right calc(0.375em + 0.4375rem); }

/* line 33, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #FD0054;
  padding-right: calc((1em + 1.75rem) * 3 / 4 + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FD0054' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FD0054' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2.5625rem/calc(0.75em + 0.875rem) calc(0.75em + 0.875rem); }
  /* line 113, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #FD0054;
    box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.25); }

/* line 122, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FD0054; }

/* line 126, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

/* line 135, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FD0054; }
  /* line 138, node_modules/bootstrap/scss/mixins/_forms.scss */
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #FD0054; }

/* line 144, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff3175;
  background-color: #ff3175; }

/* line 151, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.25); }

/* line 155, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FD0054; }

/* line 165, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FD0054; }

/* line 170, node_modules/bootstrap/scss/mixins/_forms.scss */
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #FD0054;
  box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.25); }

/* line 275, node_modules/bootstrap/scss/_forms.scss */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  /* line 283, node_modules/bootstrap/scss/_forms.scss */
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    /* line 289, node_modules/bootstrap/scss/_forms.scss */
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    /* line 297, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    /* line 306, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    /* line 313, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-control-plaintext {
      display: inline-block; }
    /* line 317, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    /* line 324, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    /* line 331, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    /* line 339, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    /* line 343, node_modules/bootstrap/scss/_forms.scss */
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* line 7, node_modules/bootstrap/scss/_buttons.scss */
.btn {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.875rem 1.5625rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 7, node_modules/bootstrap/scss/_buttons.scss */
    .btn {
      transition: none; } }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  /* line 27, node_modules/bootstrap/scss/_buttons.scss */
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
  /* line 34, node_modules/bootstrap/scss/_buttons.scss */
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  /* line 40, node_modules/bootstrap/scss/_buttons.scss */
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

/* line 55, node_modules/bootstrap/scss/_buttons.scss */
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-primary {
  color: #fff;
  background-color: #2B2024;
  border-color: #2B2024; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-primary:hover {
    color: #fff;
    background-color: #151012;
    border-color: #0e0a0c; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #151012;
    border-color: #0e0a0c;
    box-shadow: 0 0 0 0.2rem rgba(75, 65, 69, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0e0a0c;
    border-color: #060505; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 65, 69, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-success {
  color: #212529;
  background-color: #24F300;
  border-color: #24F300; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-success:hover {
    color: #fff;
    background-color: #1ecd00;
    border-color: #1cc000; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #1ecd00;
    border-color: #1cc000;
    box-shadow: 0 0 0 0.2rem rgba(36, 212, 6, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #24F300;
    border-color: #24F300; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1cc000;
    border-color: #1bb300; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 212, 6, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-warning {
  color: #212529;
  background-color: #FFB600;
  border-color: #FFB600; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-warning:hover {
    color: #212529;
    background-color: #d99b00;
    border-color: #cc9200; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #d99b00;
    border-color: #cc9200;
    box-shadow: 0 0 0 0.2rem rgba(222, 160, 6, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FFB600;
    border-color: #FFB600; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc9200;
    border-color: #bf8900; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 160, 6, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-danger {
  color: #fff;
  background-color: #FD0054;
  border-color: #FD0054; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-danger:hover {
    color: #fff;
    background-color: #d70047;
    border-color: #ca0043; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d70047;
    border-color: #ca0043;
    box-shadow: 0 0 0 0.2rem rgba(253, 38, 110, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #FD0054;
    border-color: #FD0054; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ca0043;
    border-color: #bd003f; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 38, 110, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-light {
  color: #212529;
  background-color: #FBFBFB;
  border-color: #FBFBFB; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-light:hover {
    color: #212529;
    background-color: #e8e8e8;
    border-color: #e2e2e2; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e8e8e8;
    border-color: #e2e2e2;
    box-shadow: 0 0 0 0.2rem rgba(218, 219, 220, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #FBFBFB;
    border-color: #FBFBFB; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e2e2e2;
    border-color: #dbdbdb; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(218, 219, 220, 0.5); }

/* line 66, node_modules/bootstrap/scss/_buttons.scss */
.btn-dark {
  color: #fff;
  background-color: #2B2024;
  border-color: #2B2024; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-dark:hover {
    color: #fff;
    background-color: #151012;
    border-color: #0e0a0c; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #151012;
    border-color: #0e0a0c;
    box-shadow: 0 0 0 0.2rem rgba(75, 65, 69, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0e0a0c;
    border-color: #060505; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 65, 69, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-primary {
  color: #2B2024;
  border-color: #2B2024; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2B2024;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-secondary {
  color: #fff;
  border-color: #fff; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fff;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-success {
  color: #24F300;
  border-color: #24F300; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-success:hover {
    color: #212529;
    background-color: #24F300;
    border-color: #24F300; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #24F300;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #24F300;
    border-color: #24F300; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-warning {
  color: #FFB600;
  border-color: #FFB600; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #FFB600;
    border-color: #FFB600; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 182, 0, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFB600;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFB600;
    border-color: #FFB600; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 182, 0, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-danger {
  color: #FD0054;
  border-color: #FD0054; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #FD0054;
    border-color: #FD0054; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FD0054;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FD0054;
    border-color: #FD0054; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-light {
  color: #FBFBFB;
  border-color: #FBFBFB; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-light:hover {
    color: #212529;
    background-color: #FBFBFB;
    border-color: #FBFBFB; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(251, 251, 251, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FBFBFB;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #FBFBFB;
    border-color: #FBFBFB; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 251, 251, 0.5); }

/* line 72, node_modules/bootstrap/scss/_buttons.scss */
.btn-outline-dark {
  color: #2B2024;
  border-color: #2B2024; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
  /* line 74, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }
  /* line 79, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2B2024;
    background-color: transparent; }
  /* line 85, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
    /* line 92, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }

/* line 83, node_modules/bootstrap/scss/_buttons.scss */
.btn-link {
  font-weight: 400;
  color: #414042;
  text-decoration: none; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-link:hover {
    color: #1b1a1b;
    text-decoration: underline; }
  /* line 93, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  /* line 98, node_modules/bootstrap/scss/_buttons.scss */
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

/* line 112, node_modules/bootstrap/scss/_buttons.scss */
.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 116, node_modules/bootstrap/scss/_buttons.scss */
.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 125, node_modules/bootstrap/scss/_buttons.scss */
.btn-block {
  display: block;
  width: 100%; }
  /* line 130, node_modules/bootstrap/scss/_buttons.scss */
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

/* line 139, node_modules/bootstrap/scss/_buttons.scss */
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

/* line 1, node_modules/bootstrap/scss/_transitions.scss */
.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    /* line 1, node_modules/bootstrap/scss/_transitions.scss */
    .fade {
      transition: none; } }
  /* line 4, node_modules/bootstrap/scss/_transitions.scss */
  .fade:not(.show) {
    opacity: 0; }

/* line 10, node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none; }

/* line 15, node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 15, node_modules/bootstrap/scss/_transitions.scss */
    .collapsing {
      transition: none; } }

/* line 2, node_modules/bootstrap/scss/_dropdown.scss */
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

/* line 9, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-toggle {
  white-space: nowrap; }
  /* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  /* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
  .dropdown-toggle:empty::after {
    margin-left: 0; }

/* line 17, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem; }

/* line 42, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-left {
  right: auto;
  left: 0; }

/* line 47, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  /* line 42, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  /* line 47, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

/* line 57, node_modules/bootstrap/scss/_dropdown.scss */
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 70, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 80, node_modules/bootstrap/scss/_dropdown.scss */
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

/* line 87, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

/* line 30, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

/* line 45, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::after {
  display: none; }

/* line 49, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

/* line 58, node_modules/bootstrap/scss/mixins/_caret.scss */
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

/* line 97, node_modules/bootstrap/scss/_dropdown.scss */
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

/* line 106, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

/* line 116, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F1F2F2; }

/* line 123, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #FBFBFB; }
  /* line 154, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2B2024; }
  /* line 161, node_modules/bootstrap/scss/_dropdown.scss */
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

/* line 173, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-menu.show {
  display: block; }

/* line 178, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

/* line 188, node_modules/bootstrap/scss/_dropdown.scss */
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

/* line 4, node_modules/bootstrap/scss/_button-group.scss */
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  /* line 10, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    /* line 19, node_modules/bootstrap/scss/_button-group.scss */
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

/* line 28, node_modules/bootstrap/scss/_button-group.scss */
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  /* line 33, node_modules/bootstrap/scss/_button-group.scss */
  .btn-toolbar .input-group {
    width: auto; }

/* line 40, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

/* line 46, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 51, node_modules/bootstrap/scss/_button-group.scss */
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 69, node_modules/bootstrap/scss/_button-group.scss */
.dropdown-toggle-split {
  padding-right: 1.17188rem;
  padding-left: 1.17188rem; }
  /* line 73, node_modules/bootstrap/scss/_button-group.scss */
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  /* line 79, node_modules/bootstrap/scss/_button-group.scss */
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

/* line 84, node_modules/bootstrap/scss/_button-group.scss */
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

/* line 89, node_modules/bootstrap/scss/_button-group.scss */
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

/* line 111, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  /* line 116, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  /* line 121, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  /* line 127, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 132, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 152, node_modules/bootstrap/scss/_button-group.scss */
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  /* line 156, node_modules/bootstrap/scss/_button-group.scss */
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

/* line 7, node_modules/bootstrap/scss/_input-group.scss */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  /* line 14, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    /* line 24, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  /* line 32, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  /* line 39, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  /* line 45, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  /* line 46, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 51, node_modules/bootstrap/scss/_input-group.scss */
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    /* line 55, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    /* line 57, node_modules/bootstrap/scss/_input-group.scss */
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

/* line 68, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend,
.input-group-append {
  display: flex; }
  /* line 75, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    /* line 79, node_modules/bootstrap/scss/_input-group.scss */
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  /* line 84, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

/* line 92, node_modules/bootstrap/scss/_input-group.scss */
.input-group-prepend {
  margin-right: -1px; }

/* line 93, node_modules/bootstrap/scss/_input-group.scss */
.input-group-append {
  margin-left: -1px; }

/* line 101, node_modules/bootstrap/scss/_input-group.scss */
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.5625rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #414042;
  text-align: center;
  white-space: nowrap;
  background-color: #F1F2F2;
  border: 1px solid #E6E7E8;
  border-radius: 0.1875rem; }
  /* line 117, node_modules/bootstrap/scss/_input-group.scss */
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

/* line 129, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

/* line 134, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

/* line 146, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

/* line 151, node_modules/bootstrap/scss/_input-group.scss */
.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

/* line 163, node_modules/bootstrap/scss/_input-group.scss */
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.5625rem; }

/* line 176, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* line 185, node_modules/bootstrap/scss/_input-group.scss */
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/* line 10, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

/* line 17, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

/* line 22, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  /* line 30, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2B2024;
    background-color: #2B2024; }
  /* line 37, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
  /* line 46, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #745661; }
  /* line 50, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #916c7a;
    border-color: #916c7a; }
  /* line 60, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    /* line 63, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F1F2F2; }

/* line 74, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  /* line 82, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  /* line 97, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

/* line 115, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem; }

/* line 120, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

/* line 126, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2B2024;
  background-color: #2B2024; }

/* line 131, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

/* line 137, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 32, 36, 0.5); }

/* line 140, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(43, 32, 36, 0.5); }

/* line 151, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-label::before {
  border-radius: 50%; }

/* line 157, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

/* line 163, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 32, 36, 0.5); }

/* line 174, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-switch {
  padding-left: 2.25rem; }
  /* line 178, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  /* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      /* line 186, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-switch .custom-control-label::after {
        transition: none; } }
  /* line 199, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  /* line 206, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(43, 32, 36, 0.5); }

/* line 219, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.75rem + 2px);
  padding: 0.875rem 2.5625rem 0.875rem 1.5625rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #414042;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5625rem center/8px 10px;
  border: 1px solid #E6E7E8;
  border-radius: 0.1875rem;
  appearance: none; }
  /* line 236, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:focus {
    border-color: #745661;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
    /* line 246, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-select:focus::-ms-value {
      color: #414042;
      background-color: #fff; }
  /* line 257, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.5625rem;
    background-image: none; }
  /* line 264, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:disabled {
    color: #6c757d;
    background-color: #F1F2F2; }
  /* line 270, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select::-ms-expand {
    display: none; }
  /* line 275, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #414042; }

/* line 281, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

/* line 289, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

/* line 302, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.75rem + 2px);
  margin-bottom: 0; }

/* line 310, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.75rem + 2px);
  margin: 0;
  opacity: 0; }
  /* line 318, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #745661;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
  /* line 324, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #F1F2F2; }
  /* line 330, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  /* line 335, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

/* line 340, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.75rem + 2px);
  padding: 0.875rem 1.5625rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #414042;
  background-color: #fff;
  border: 1px solid #E6E7E8;
  border-radius: 0.1875rem; }
  /* line 357, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.75rem);
    padding: 0.875rem 1.5625rem;
    line-height: 1.5;
    color: #414042;
    content: "Browse";
    background-color: #F1F2F2;
    border-left: inherit;
    border-radius: 0 0.1875rem 0.1875rem 0; }

/* line 381, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  /* line 388, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:focus {
    outline: none; }
    /* line 393, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
    /* line 394, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
    /* line 395, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }
  /* line 398, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-focus-outer {
    border: 0; }
  /* line 402, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2B2024;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 402, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    /* line 413, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-webkit-slider-thumb:active {
      background-color: #916c7a; }
  /* line 418, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #E6E7E8;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 429, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2B2024;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 429, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-moz-range-thumb {
        transition: none; } }
    /* line 439, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-moz-range-thumb:active {
      background-color: #916c7a; }
  /* line 444, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #E6E7E8;
    border-color: transparent;
    border-radius: 1rem; }
  /* line 455, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2B2024;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      /* line 455, node_modules/bootstrap/scss/_custom-forms.scss */
      .custom-range::-ms-thumb {
        transition: none; } }
    /* line 468, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-range::-ms-thumb:active {
      background-color: #916c7a; }
  /* line 473, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  /* line 484, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-lower {
    background-color: #E6E7E8;
    border-radius: 1rem; }
  /* line 489, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #E6E7E8;
    border-radius: 1rem; }
  /* line 496, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  /* line 500, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  /* line 504, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  /* line 508, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  /* line 512, node_modules/bootstrap/scss/_custom-forms.scss */
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

/* line 518, node_modules/bootstrap/scss/_custom-forms.scss */
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 518, node_modules/bootstrap/scss/_custom-forms.scss */
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

/* line 6, node_modules/bootstrap/scss/_nav.scss */
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

/* line 14, node_modules/bootstrap/scss/_nav.scss */
.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  /* line 24, node_modules/bootstrap/scss/_nav.scss */
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

/* line 35, node_modules/bootstrap/scss/_nav.scss */
.nav-tabs {
  border-bottom: 1px solid #E6E7E8; }
  /* line 38, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  /* line 42, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #F1F2F2 #F1F2F2 #E6E7E8; }
    /* line 50, node_modules/bootstrap/scss/_nav.scss */
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  /* line 57, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #E6E7E8 #E6E7E8 #fff; }
  /* line 64, node_modules/bootstrap/scss/_nav.scss */
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

/* line 78, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link {
  border-radius: 0.1875rem; }

/* line 82, node_modules/bootstrap/scss/_nav.scss */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2B2024; }

/* line 95, node_modules/bootstrap/scss/_nav.scss */
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

/* line 102, node_modules/bootstrap/scss/_nav.scss */
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

/* line 115, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .tab-pane {
  display: none; }

/* line 118, node_modules/bootstrap/scss/_nav.scss */
.tab-content > .active {
  display: block; }

/* line 18, node_modules/bootstrap/scss/_navbar.scss */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  /* line 28, node_modules/bootstrap/scss/_navbar.scss */
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

/* line 52, node_modules/bootstrap/scss/_navbar.scss */
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

/* line 71, node_modules/bootstrap/scss/_navbar.scss */
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  /* line 78, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  /* line 83, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

/* line 94, node_modules/bootstrap/scss/_navbar.scss */
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

/* line 109, node_modules/bootstrap/scss/_navbar.scss */
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

/* line 118, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

/* line 133, node_modules/bootstrap/scss/_navbar.scss */
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  /* line 150, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    /* line 173, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      /* line 176, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      /* line 180, node_modules/bootstrap/scss/_navbar.scss */
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    /* line 187, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    /* line 202, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    /* line 209, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

/* line 150, node_modules/bootstrap/scss/_navbar.scss */
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  /* line 152, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  /* line 173, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    /* line 176, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    /* line 180, node_modules/bootstrap/scss/_navbar.scss */
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  /* line 187, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  /* line 202, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  /* line 209, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-expand .navbar-toggler {
    display: none; }

/* line 224, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

/* line 233, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  /* line 240, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

/* line 245, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

/* line 253, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

/* line 258, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 262, node_modules/bootstrap/scss/_navbar.scss */
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  /* line 264, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

/* line 276, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-brand {
  color: #fff; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

/* line 285, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  /* line 292, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

/* line 297, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

/* line 305, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

/* line 310, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

/* line 314, node_modules/bootstrap/scss/_navbar.scss */
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  /* line 316, node_modules/bootstrap/scss/_navbar.scss */
  .navbar-dark .navbar-text a {
    color: #fff; }
    /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

/* line 5, node_modules/bootstrap/scss/_card.scss */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem; }
  /* line 17, node_modules/bootstrap/scss/_card.scss */
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  /* line 22, node_modules/bootstrap/scss/_card.scss */
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    /* line 26, node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.1875rem - 1px);
      border-top-right-radius: calc(0.1875rem - 1px); }
    /* line 31, node_modules/bootstrap/scss/_card.scss */
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.1875rem - 1px);
      border-bottom-left-radius: calc(0.1875rem - 1px); }

/* line 38, node_modules/bootstrap/scss/_card.scss */
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

/* line 49, node_modules/bootstrap/scss/_card.scss */
.card-title {
  margin-bottom: 0.75rem; }

/* line 53, node_modules/bootstrap/scss/_card.scss */
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

/* line 58, node_modules/bootstrap/scss/_card.scss */
.card-text:last-child {
  margin-bottom: 0; }

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.card-link:hover {
  text-decoration: none; }

/* line 67, node_modules/bootstrap/scss/_card.scss */
.card-link + .card-link {
  margin-left: 1.25rem; }

/* line 76, node_modules/bootstrap/scss/_card.scss */
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 83, node_modules/bootstrap/scss/_card.scss */
  .card-header:first-child {
    border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0; }
  /* line 88, node_modules/bootstrap/scss/_card.scss */
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

/* line 94, node_modules/bootstrap/scss/_card.scss */
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 100, node_modules/bootstrap/scss/_card.scss */
  .card-footer:last-child {
    border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px); }

/* line 110, node_modules/bootstrap/scss/_card.scss */
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

/* line 117, node_modules/bootstrap/scss/_card.scss */
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

/* line 123, node_modules/bootstrap/scss/_card.scss */
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

/* line 132, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

/* line 139, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }

/* line 144, node_modules/bootstrap/scss/_card.scss */
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

/* line 153, node_modules/bootstrap/scss/_card.scss */
.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 152, node_modules/bootstrap/scss/_card.scss */
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    /* line 163, node_modules/bootstrap/scss/_card.scss */
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

/* line 181, node_modules/bootstrap/scss/_card.scss */
.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  /* line 178, node_modules/bootstrap/scss/_card.scss */
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    /* line 190, node_modules/bootstrap/scss/_card.scss */
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      /* line 195, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      /* line 202, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        /* line 205, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        /* line 210, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      /* line 217, node_modules/bootstrap/scss/_card.scss */
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        /* line 220, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        /* line 225, node_modules/bootstrap/scss/_card.scss */
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

/* line 242, node_modules/bootstrap/scss/_card.scss */
.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  /* line 241, node_modules/bootstrap/scss/_card.scss */
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    /* line 252, node_modules/bootstrap/scss/_card.scss */
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

/* line 265, node_modules/bootstrap/scss/_card.scss */
.accordion > .card {
  overflow: hidden; }
  /* line 268, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  /* line 273, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  /* line 277, node_modules/bootstrap/scss/_card.scss */
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

/* line 1, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F1F2F2;
  border-radius: 0.1875rem; }

/* line 12, node_modules/bootstrap/scss/_breadcrumb.scss */
.breadcrumb-item {
  display: flex; }
  /* line 16, node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    /* line 19, node_modules/bootstrap/scss/_breadcrumb.scss */
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  /* line 33, node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  /* line 37, node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  /* line 41, node_modules/bootstrap/scss/_breadcrumb.scss */
  .breadcrumb-item.active {
    color: #6c757d; }

/* line 1, node_modules/bootstrap/scss/_pagination.scss */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem; }

/* line 7, node_modules/bootstrap/scss/_pagination.scss */
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #414042;
  background-color: #fff;
  border: 1px solid #E6E7E8; }
  /* line 18, node_modules/bootstrap/scss/_pagination.scss */
  .page-link:hover {
    z-index: 2;
    color: #1b1a1b;
    text-decoration: none;
    background-color: #F1F2F2;
    border-color: #E6E7E8; }
  /* line 26, node_modules/bootstrap/scss/_pagination.scss */
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.25); }

/* line 35, node_modules/bootstrap/scss/_pagination.scss */
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

/* line 41, node_modules/bootstrap/scss/_pagination.scss */
.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

/* line 46, node_modules/bootstrap/scss/_pagination.scss */
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2B2024;
  border-color: #2B2024; }

/* line 53, node_modules/bootstrap/scss/_pagination.scss */
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #E6E7E8; }

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

/* line 4, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

/* line 12, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

/* line 17, node_modules/bootstrap/scss/mixins/_pagination.scss */
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

/* line 6, node_modules/bootstrap/scss/_badge.scss */
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 6, node_modules/bootstrap/scss/_badge.scss */
    .badge {
      transition: none; } }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  /* line 25, node_modules/bootstrap/scss/_badge.scss */
  .badge:empty {
    display: none; }

/* line 31, node_modules/bootstrap/scss/_badge.scss */
.btn .badge {
  position: relative;
  top: -1px; }

/* line 40, node_modules/bootstrap/scss/_badge.scss */
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-primary {
  color: #fff;
  background-color: #2B2024; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0e0a0c; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-secondary {
  color: #212529;
  background-color: #fff; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e6e6e6; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-success {
  color: #212529;
  background-color: #24F300; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #1cc000; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 243, 0, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-warning {
  color: #212529;
  background-color: #FFB600; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cc9200; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 182, 0, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-danger {
  color: #fff;
  background-color: #FD0054; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ca0043; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 0, 84, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-light {
  color: #212529;
  background-color: #FBFBFB; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #e2e2e2; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(251, 251, 251, 0.5); }

/* line 51, node_modules/bootstrap/scss/_badge.scss */
.badge-dark {
  color: #fff;
  background-color: #2B2024; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #0e0a0c; }
  /* line 11, node_modules/bootstrap/scss/mixins/_badge.scss */
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 32, 36, 0.5); }

/* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F1F2F2;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    /* line 1, node_modules/bootstrap/scss/_jumbotron.scss */
    .jumbotron {
      padding: 4rem 2rem; } }

/* line 13, node_modules/bootstrap/scss/_jumbotron.scss */
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

/* line 5, node_modules/bootstrap/scss/_alert.scss */
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }

/* line 14, node_modules/bootstrap/scss/_alert.scss */
.alert-heading {
  color: inherit; }

/* line 20, node_modules/bootstrap/scss/_alert.scss */
.alert-link {
  font-weight: 700; }

/* line 29, node_modules/bootstrap/scss/_alert.scss */
.alert-dismissible {
  padding-right: 4rem; }
  /* line 33, node_modules/bootstrap/scss/_alert.scss */
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-primary {
  color: #161113;
  background-color: #d5d2d3;
  border-color: #c4c1c2; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary hr {
    border-top-color: #b8b4b5; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-primary .alert-link {
    color: black; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-secondary .alert-link {
    color: #6c6c6c; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-success {
  color: #137e00;
  background-color: #d3fdcc;
  border-color: #c2fcb8; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success hr {
    border-top-color: #adfba0; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-success .alert-link {
    color: #0b4b00; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info hr {
    border-top-color: #abdde5; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-info .alert-link {
    color: #062c33; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-warning {
  color: #855f00;
  background-color: #fff0cc;
  border-color: #ffebb8; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning hr {
    border-top-color: #ffe49f; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-warning .alert-link {
    color: #523b00; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-danger {
  color: #84002c;
  background-color: #ffccdd;
  border-color: #feb8cf; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger hr {
    border-top-color: #fe9fbe; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-danger .alert-link {
    color: #51001b; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-light {
  color: #838383;
  background-color: #fefefe;
  border-color: #fefefe; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light hr {
    border-top-color: #f1f1f1; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-light .alert-link {
    color: #6a6a6a; }

/* line 48, node_modules/bootstrap/scss/_alert.scss */
.alert-dark {
  color: #161113;
  background-color: #d5d2d3;
  border-color: #c4c1c2; }
  /* line 6, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark hr {
    border-top-color: #b8b4b5; }
  /* line 10, node_modules/bootstrap/scss/mixins/_alert.scss */
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

/* line 9, node_modules/bootstrap/scss/_progress.scss */
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #F1F2F2;
  border-radius: 0.1875rem; }

/* line 20, node_modules/bootstrap/scss/_progress.scss */
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2B2024;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 20, node_modules/bootstrap/scss/_progress.scss */
    .progress-bar {
      transition: none; } }

/* line 32, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

/* line 38, node_modules/bootstrap/scss/_progress.scss */
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    /* line 38, node_modules/bootstrap/scss/_progress.scss */
    .progress-bar-animated {
      animation: none; } }

/* line 1, node_modules/bootstrap/scss/_media.scss */
.media {
  display: flex;
  align-items: flex-start; }

/* line 6, node_modules/bootstrap/scss/_media.scss */
.media-body {
  flex: 1; }

/* line 5, node_modules/bootstrap/scss/_list-group.scss */
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.1875rem; }

/* line 21, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #FBFBFB; }
  /* line 34, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item-action:active {
    color: #212529;
    background-color: #F1F2F2; }

/* line 45, node_modules/bootstrap/scss/_list-group.scss */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  /* line 54, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  /* line 58, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  /* line 62, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  /* line 70, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2B2024;
    border-color: #2B2024; }
  /* line 77, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    /* line 80, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

/* line 96, node_modules/bootstrap/scss/_list-group.scss */
.list-group-horizontal {
  flex-direction: row; }
  /* line 100, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0; }
  /* line 105, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0; }
  /* line 110, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  /* line 114, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    /* line 118, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-sm {
    flex-direction: row; }
    /* line 100, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    /* line 105, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    /* line 110, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    /* line 114, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-md {
    flex-direction: row; }
    /* line 100, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    /* line 105, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    /* line 110, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    /* line 114, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-lg {
    flex-direction: row; }
    /* line 100, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    /* line 105, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    /* line 110, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    /* line 114, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  /* line 96, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-horizontal-xl {
    flex-direction: row; }
    /* line 100, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    /* line 105, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    /* line 110, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    /* line 114, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      /* line 118, node_modules/bootstrap/scss/_list-group.scss */
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

/* line 134, node_modules/bootstrap/scss/_list-group.scss */
.list-group-flush {
  border-radius: 0; }
  /* line 137, node_modules/bootstrap/scss/_list-group.scss */
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    /* line 140, node_modules/bootstrap/scss/_list-group.scss */
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-primary {
  color: #161113;
  background-color: #c4c1c2; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #161113;
    background-color: #b8b4b5; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #161113;
    border-color: #161113; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-success {
  color: #137e00;
  background-color: #c2fcb8; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #137e00;
    background-color: #adfba0; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #137e00;
    border-color: #137e00; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-warning {
  color: #855f00;
  background-color: #ffebb8; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #855f00;
    background-color: #ffe49f; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #855f00;
    border-color: #855f00; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-danger {
  color: #84002c;
  background-color: #feb8cf; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #84002c;
    background-color: #fe9fbe; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #84002c;
    border-color: #84002c; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-light {
  color: #838383;
  background-color: #fefefe; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #838383;
    background-color: #f1f1f1; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #838383;
    border-color: #838383; }

/* line 4, node_modules/bootstrap/scss/mixins/_list-group.scss */
.list-group-item-dark {
  color: #161113;
  background-color: #c4c1c2; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #161113;
    background-color: #b8b4b5; }
  /* line 14, node_modules/bootstrap/scss/mixins/_list-group.scss */
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #161113;
    border-color: #161113; }

/* line 1, node_modules/bootstrap/scss/_close.scss */
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    /* line 1, node_modules/bootstrap/scss/_close.scss */
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:hover {
    color: #000;
    text-decoration: none; }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

/* line 29, node_modules/bootstrap/scss/_close.scss */
button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

/* line 38, node_modules/bootstrap/scss/_close.scss */
a.close.disabled {
  pointer-events: none; }

/* line 1, node_modules/bootstrap/scss/_toasts.scss */
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  /* line 14, node_modules/bootstrap/scss/_toasts.scss */
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  /* line 18, node_modules/bootstrap/scss/_toasts.scss */
  .toast.showing {
    opacity: 1; }
  /* line 22, node_modules/bootstrap/scss/_toasts.scss */
  .toast.show {
    display: block;
    opacity: 1; }
  /* line 27, node_modules/bootstrap/scss/_toasts.scss */
  .toast.hide {
    display: none; }

/* line 32, node_modules/bootstrap/scss/_toasts.scss */
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

/* line 42, node_modules/bootstrap/scss/_toasts.scss */
.toast-body {
  padding: 0.75rem; }

/* line 7, node_modules/bootstrap/scss/_modal.scss */
.modal-open {
  overflow: hidden; }
  /* line 11, node_modules/bootstrap/scss/_modal.scss */
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

/* line 18, node_modules/bootstrap/scss/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

/* line 36, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  /* line 44, node_modules/bootstrap/scss/_modal.scss */
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      /* line 44, node_modules/bootstrap/scss/_modal.scss */
      .modal.fade .modal-dialog {
        transition: none; } }
  /* line 48, node_modules/bootstrap/scss/_modal.scss */
  .modal.show .modal-dialog {
    transform: none; }
  /* line 53, node_modules/bootstrap/scss/_modal.scss */
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

/* line 58, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  /* line 62, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  /* line 67, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  /* line 72, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

/* line 77, node_modules/bootstrap/scss/_modal.scss */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  /* line 83, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  /* line 91, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    /* line 96, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    /* line 100, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

/* line 107, node_modules/bootstrap/scss/_modal.scss */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

/* line 125, node_modules/bootstrap/scss/_modal.scss */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  /* line 135, node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.fade {
    opacity: 0; }
  /* line 136, node_modules/bootstrap/scss/_modal.scss */
  .modal-backdrop.show {
    opacity: 0.5; }

/* line 141, node_modules/bootstrap/scss/_modal.scss */
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E6E7E8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 149, node_modules/bootstrap/scss/_modal.scss */
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

/* line 157, node_modules/bootstrap/scss/_modal.scss */
.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

/* line 164, node_modules/bootstrap/scss/_modal.scss */
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

/* line 173, node_modules/bootstrap/scss/_modal.scss */
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E6E7E8;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  /* line 186, node_modules/bootstrap/scss/_modal.scss */
  .modal-footer > * {
    margin: 0.25rem; }

/* line 192, node_modules/bootstrap/scss/_modal.scss */
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  /* line 203, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  /* line 208, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    /* line 211, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  /* line 216, node_modules/bootstrap/scss/_modal.scss */
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    /* line 219, node_modules/bootstrap/scss/_modal.scss */
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  /* line 229, node_modules/bootstrap/scss/_modal.scss */
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  /* line 233, node_modules/bootstrap/scss/_modal.scss */
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  /* line 240, node_modules/bootstrap/scss/_modal.scss */
  .modal-xl {
    max-width: 1140px; } }

/* line 2, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  /* line 15, node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip.show {
    opacity: 0.9; }
  /* line 17, node_modules/bootstrap/scss/_tooltip.scss */
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    /* line 23, node_modules/bootstrap/scss/_tooltip.scss */
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 32, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  /* line 35, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    /* line 38, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

/* line 46, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  /* line 49, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 54, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

/* line 62, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  /* line 65, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    /* line 68, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

/* line 76, node_modules/bootstrap/scss/_tooltip.scss */
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  /* line 79, node_modules/bootstrap/scss/_tooltip.scss */
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    /* line 84, node_modules/bootstrap/scss/_tooltip.scss */
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

/* line 108, node_modules/bootstrap/scss/_tooltip.scss */
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem; }

/* line 1, node_modules/bootstrap/scss/_popover.scss */
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  /* line 20, node_modules/bootstrap/scss/_popover.scss */
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    /* line 27, node_modules/bootstrap/scss/_popover.scss */
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

/* line 38, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  /* line 41, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    /* line 44, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    /* line 50, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

/* line 58, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  /* line 61, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 67, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    /* line 73, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

/* line 81, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  /* line 84, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    /* line 87, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    /* line 93, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  /* line 101, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

/* line 113, node_modules/bootstrap/scss/_popover.scss */
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  /* line 116, node_modules/bootstrap/scss/_popover.scss */
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    /* line 122, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    /* line 128, node_modules/bootstrap/scss/_popover.scss */
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

/* line 153, node_modules/bootstrap/scss/_popover.scss */
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  /* line 162, node_modules/bootstrap/scss/_popover.scss */
  .popover-header:empty {
    display: none; }

/* line 167, node_modules/bootstrap/scss/_popover.scss */
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

/* line 14, node_modules/bootstrap/scss/_carousel.scss */
.carousel {
  position: relative; }

/* line 18, node_modules/bootstrap/scss/_carousel.scss */
.carousel.pointer-event {
  touch-action: pan-y; }

/* line 22, node_modules/bootstrap/scss/_carousel.scss */
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  /* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

/* line 29, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    /* line 29, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-item {
      transition: none; } }

/* line 39, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* line 45, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

/* line 50, node_modules/bootstrap/scss/_carousel.scss */
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

/* line 61, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

/* line 67, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

/* line 74, node_modules/bootstrap/scss/_carousel.scss */
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    /* line 74, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

/* line 87, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    /* line 87, node_modules/bootstrap/scss/_carousel.scss */
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  /* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

/* line 111, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev {
  left: 0; }

/* line 117, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next {
  right: 0; }

/* line 125, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

/* line 132, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

/* line 135, node_modules/bootstrap/scss/_carousel.scss */
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

/* line 145, node_modules/bootstrap/scss/_carousel.scss */
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  /* line 159, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      /* line 159, node_modules/bootstrap/scss/_carousel.scss */
      .carousel-indicators li {
        transition: none; } }
  /* line 177, node_modules/bootstrap/scss/_carousel.scss */
  .carousel-indicators .active {
    opacity: 1; }

/* line 187, node_modules/bootstrap/scss/_carousel.scss */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

/* line 9, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

/* line 21, node_modules/bootstrap/scss/_spinners.scss */
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

/* line 41, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

/* line 53, node_modules/bootstrap/scss/_spinners.scss */
.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

/* line 3, node_modules/bootstrap/scss/utilities/_align.scss */
.align-baseline {
  vertical-align: baseline !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_align.scss */
.align-top {
  vertical-align: top !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_align.scss */
.align-middle {
  vertical-align: middle !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_align.scss */
.align-bottom {
  vertical-align: bottom !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-bottom {
  vertical-align: text-bottom !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_align.scss */
.align-text-top {
  vertical-align: text-top !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-primary {
  background-color: #2B2024 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0e0a0c !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-secondary {
  background-color: #fff !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-success {
  background-color: #24F300 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1cc000 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-info {
  background-color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-warning {
  background-color: #FFB600 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9200 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-danger {
  background-color: #FD0054 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ca0043 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-light {
  background-color: #FBFBFB !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e2e2e2 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_background-variant.scss */
.bg-dark {
  background-color: #2B2024 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0e0a0c !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-white {
  background-color: #fff !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_background.scss */
.bg-transparent {
  background-color: transparent !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_borders.scss */
.border {
  border: 1px solid #E6E7E8 !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top {
  border-top: 1px solid #E6E7E8 !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right {
  border-right: 1px solid #E6E7E8 !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom {
  border-bottom: 1px solid #E6E7E8 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left {
  border-left: 1px solid #E6E7E8 !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-0 {
  border: 0 !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-top-0 {
  border-top: 0 !important; }

/* line 15, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-right-0 {
  border-right: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-bottom-0 {
  border-bottom: 0 !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-left-0 {
  border-left: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-primary {
  border-color: #2B2024 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-secondary {
  border-color: #fff !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-success {
  border-color: #24F300 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-info {
  border-color: #17a2b8 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-warning {
  border-color: #FFB600 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-danger {
  border-color: #FD0054 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-light {
  border-color: #FBFBFB !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-dark {
  border-color: #2B2024 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_borders.scss */
.border-white {
  border-color: #fff !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-sm {
  border-radius: 0.2rem !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded {
  border-radius: 0.1875rem !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

/* line 61, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-lg {
  border-radius: 0.3rem !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-circle {
  border-radius: 50% !important; }

/* line 69, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-pill {
  border-radius: 50rem !important; }

/* line 73, node_modules/bootstrap/scss/utilities/_borders.scss */
.rounded-0 {
  border-radius: 0 !important; }

/* line 2, node_modules/bootstrap/scss/mixins/_clearfix.scss */
.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-none {
  display: none !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline {
  display: inline !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-block {
  display: inline-block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-block {
  display: block !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table {
  display: table !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-row {
  display: table-row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-table-cell {
  display: table-cell !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-flex {
  display: flex !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-none {
    display: none !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline {
    display: inline !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-block {
    display: inline-block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-block {
    display: block !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table {
    display: table !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-row {
    display: table-row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-table-cell {
    display: table-cell !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-flex {
    display: flex !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-none {
    display: none !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline {
    display: inline !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-block {
    display: inline-block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-block {
    display: block !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table {
    display: table !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-row {
    display: table-row !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-table-cell {
    display: table-cell !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-flex {
    display: flex !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_display.scss */
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* line 3, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  /* line 10, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive::before {
    display: block;
    content: ""; }
  /* line 15, node_modules/bootstrap/scss/utilities/_embed.scss */
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-16by9::before {
  padding-top: 56.25%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-4by3::before {
  padding-top: 75%; }

/* line 35, node_modules/bootstrap/scss/utilities/_embed.scss */
.embed-responsive-1by1::before {
  padding-top: 100%; }

/* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row {
  flex-direction: row !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column {
  flex-direction: column !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-row-reverse {
  flex-direction: row-reverse !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-column-reverse {
  flex-direction: column-reverse !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap {
  flex-wrap: wrap !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-nowrap {
  flex-wrap: nowrap !important; }

/* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-fill {
  flex: 1 1 auto !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-0 {
  flex-grow: 0 !important; }

/* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-grow-1 {
  flex-grow: 1 !important; }

/* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-0 {
  flex-shrink: 0 !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
.flex-shrink-1 {
  flex-shrink: 1 !important; }

/* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-start {
  justify-content: flex-start !important; }

/* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-end {
  justify-content: flex-end !important; }

/* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-center {
  justify-content: center !important; }

/* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-between {
  justify-content: space-between !important; }

/* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
.justify-content-around {
  justify-content: space-around !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-start {
  align-items: flex-start !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-end {
  align-items: flex-end !important; }

/* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-center {
  align-items: center !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-baseline {
  align-items: baseline !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-items-stretch {
  align-items: stretch !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-start {
  align-content: flex-start !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-end {
  align-content: flex-end !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-center {
  align-content: center !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-between {
  align-content: space-between !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-around {
  align-content: space-around !important; }

/* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-content-stretch {
  align-content: stretch !important; }

/* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-auto {
  align-self: auto !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-start {
  align-self: flex-start !important; }

/* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-end {
  align-self: flex-end !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-center {
  align-self: center !important; }

/* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-baseline {
  align-self: baseline !important; }

/* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-sm-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-sm-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-sm-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-md-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-md-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-md-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-lg-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-lg-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-lg-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row {
    flex-direction: row !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column {
    flex-direction: column !important; }
  /* line 13, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  /* line 14, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  /* line 17, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  /* line 18, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  /* line 19, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  /* line 21, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  /* line 22, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_flex.scss */
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  /* line 25, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  /* line 26, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  /* line 27, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-center {
    justify-content: center !important; }
  /* line 28, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-between {
    justify-content: space-between !important; }
  /* line 29, node_modules/bootstrap/scss/utilities/_flex.scss */
  .justify-content-xl-around {
    justify-content: space-around !important; }
  /* line 31, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-start {
    align-items: flex-start !important; }
  /* line 32, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-end {
    align-items: flex-end !important; }
  /* line 33, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-center {
    align-items: center !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-baseline {
    align-items: baseline !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-items-xl-stretch {
    align-items: stretch !important; }
  /* line 37, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-start {
    align-content: flex-start !important; }
  /* line 38, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-end {
    align-content: flex-end !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-center {
    align-content: center !important; }
  /* line 40, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-between {
    align-content: space-between !important; }
  /* line 41, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-around {
    align-content: space-around !important; }
  /* line 42, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-content-xl-stretch {
    align-content: stretch !important; }
  /* line 44, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-auto {
    align-self: auto !important; }
  /* line 45, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-start {
    align-self: flex-start !important; }
  /* line 46, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-end {
    align-self: flex-end !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-center {
    align-self: center !important; }
  /* line 48, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-baseline {
    align-self: baseline !important; }
  /* line 49, node_modules/bootstrap/scss/utilities/_flex.scss */
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
.float-left {
  float: left !important; }

/* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
.float-right {
  float: right !important; }

/* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
.float-none {
  float: none !important; }

@media (min-width: 576px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  /* line 7, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-left {
    float: left !important; }
  /* line 8, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-right {
    float: right !important; }
  /* line 9, node_modules/bootstrap/scss/utilities/_float.scss */
  .float-xl-none {
    float: none !important; } }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-all {
  user-select: all !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-auto {
  user-select: auto !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_interactions.scss */
.user-select-none {
  user-select: none !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-auto {
  overflow: auto !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_overflow.scss */
.overflow-hidden {
  overflow: hidden !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-static {
  position: static !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-relative {
  position: relative !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-absolute {
  position: absolute !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-fixed {
  position: fixed !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_position.scss */
.position-sticky {
  position: sticky !important; }

/* line 10, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

/* line 18, node_modules/bootstrap/scss/utilities/_position.scss */
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  /* line 26, node_modules/bootstrap/scss/utilities/_position.scss */
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

/* line 5, node_modules/bootstrap/scss/utilities/_screenreaders.scss */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

/* line 25, node_modules/bootstrap/scss/mixins/_screen-reader.scss */
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

/* line 3, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

/* line 4, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

/* line 5, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

/* line 6, node_modules/bootstrap/scss/utilities/_shadows.scss */
.shadow-none {
  box-shadow: none !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-25 {
  width: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-50 {
  width: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-75 {
  width: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-100 {
  width: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.w-auto {
  width: auto !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-25 {
  height: 25% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-50 {
  height: 50% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-75 {
  height: 75% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-100 {
  height: 100% !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_sizing.scss */
.h-auto {
  height: auto !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mw-100 {
  max-width: 100% !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_sizing.scss */
.mh-100 {
  max-height: 100% !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vw-100 {
  min-width: 100vw !important; }

/* line 17, node_modules/bootstrap/scss/utilities/_sizing.scss */
.min-vh-100 {
  min-height: 100vh !important; }

/* line 19, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vw-100 {
  width: 100vw !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_sizing.scss */
.vh-100 {
  height: 100vh !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-0 {
  margin: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-0,
.my-0 {
  margin-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-0,
.mx-0 {
  margin-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-0,
.mx-0 {
  margin-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-1 {
  margin: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-2 {
  margin: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-3 {
  margin: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-3,
.my-3 {
  margin-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-4 {
  margin: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-5 {
  margin: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-5,
.my-5 {
  margin-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-0 {
  padding: 0 !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-0,
.py-0 {
  padding-top: 0 !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-0,
.px-0 {
  padding-right: 0 !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-0,
.px-0 {
  padding-left: 0 !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-1 {
  padding: 0.25rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-2 {
  padding: 0.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-3 {
  padding: 1rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-3,
.py-3 {
  padding-top: 1rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-3,
.px-3 {
  padding-right: 1rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-3,
.px-3 {
  padding-left: 1rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-4 {
  padding: 1.5rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
.p-5 {
  padding: 3rem !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pt-5,
.py-5 {
  padding-top: 3rem !important; }

/* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pr-5,
.px-5 {
  padding-right: 3rem !important; }

/* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
.pl-5,
.px-5 {
  padding-left: 3rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n1 {
  margin: -0.25rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n2 {
  margin: -0.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n3 {
  margin: -1rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n4 {
  margin: -1.5rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

/* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-n5 {
  margin: -3rem !important; }

/* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

/* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

/* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
.m-auto {
  margin: auto !important; }

/* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mt-auto,
.my-auto {
  margin-top: auto !important; }

/* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mr-auto,
.mx-auto {
  margin-right: auto !important; }

/* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

/* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-sm-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-sm-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-md-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-md-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-lg-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-lg-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-0 {
    margin: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-1 {
    margin: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-2 {
    margin: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-3 {
    margin: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-4 {
    margin: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-5 {
    margin: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-0 {
    padding: 0 !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-1 {
    padding: 0.25rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-2 {
    padding: 0.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-3 {
    padding: 1rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-4 {
    padding: 1.5rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  /* line 11, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .p-xl-5 {
    padding: 3rem !important; }
  /* line 12, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  /* line 16, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  /* line 20, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n1 {
    margin: -0.25rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n2 {
    margin: -0.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n3 {
    margin: -1rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n4 {
    margin: -1.5rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  /* line 34, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-n5 {
    margin: -3rem !important; }
  /* line 35, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  /* line 39, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  /* line 43, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  /* line 47, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  /* line 55, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .m-xl-auto {
    margin: auto !important; }
  /* line 56, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  /* line 60, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  /* line 64, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  /* line 68, node_modules/bootstrap/scss/utilities/_spacing.scss */
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/* line 6, node_modules/bootstrap/scss/utilities/_stretched-link.scss */
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

/* line 7, node_modules/bootstrap/scss/utilities/_text.scss */
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_text.scss */
.text-justify {
  text-align: justify !important; }

/* line 12, node_modules/bootstrap/scss/utilities/_text.scss */
.text-wrap {
  white-space: normal !important; }

/* line 13, node_modules/bootstrap/scss/utilities/_text.scss */
.text-nowrap {
  white-space: nowrap !important; }

/* line 14, node_modules/bootstrap/scss/utilities/_text.scss */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
.text-left {
  text-align: left !important; }

/* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
.text-right {
  text-align: right !important; }

/* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  /* line 22, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-left {
    text-align: left !important; }
  /* line 23, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-right {
    text-align: right !important; }
  /* line 24, node_modules/bootstrap/scss/utilities/_text.scss */
  .text-xl-center {
    text-align: center !important; } }

/* line 30, node_modules/bootstrap/scss/utilities/_text.scss */
.text-lowercase {
  text-transform: lowercase !important; }

/* line 31, node_modules/bootstrap/scss/utilities/_text.scss */
.text-uppercase {
  text-transform: uppercase !important; }

/* line 32, node_modules/bootstrap/scss/utilities/_text.scss */
.text-capitalize {
  text-transform: capitalize !important; }

/* line 36, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-light {
  font-weight: 300 !important; }

/* line 37, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-lighter {
  font-weight: lighter !important; }

/* line 38, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-normal {
  font-weight: 400 !important; }

/* line 39, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bold {
  font-weight: 700 !important; }

/* line 40, node_modules/bootstrap/scss/utilities/_text.scss */
.font-weight-bolder {
  font-weight: bolder !important; }

/* line 41, node_modules/bootstrap/scss/utilities/_text.scss */
.font-italic {
  font-style: italic !important; }

/* line 45, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white {
  color: #fff !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-primary {
  color: #2B2024 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-primary:hover, a.text-primary:focus {
  color: black !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-secondary {
  color: #fff !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-success {
  color: #24F300 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-success:hover, a.text-success:focus {
  color: #19a700 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-info {
  color: #17a2b8 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-warning {
  color: #FFB600 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-warning:hover, a.text-warning:focus {
  color: #b37f00 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-danger {
  color: #FD0054 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-danger:hover, a.text-danger:focus {
  color: #b1003b !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-light {
  color: #FBFBFB !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-light:hover, a.text-light:focus {
  color: #d5d5d5 !important; }

/* line 6, node_modules/bootstrap/scss/mixins/_text-emphasis.scss */
.text-dark {
  color: #2B2024 !important; }

/* line 17, node_modules/bootstrap/scss/mixins/_hover.scss */
a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

/* line 51, node_modules/bootstrap/scss/utilities/_text.scss */
.text-body {
  color: #212529 !important; }

/* line 52, node_modules/bootstrap/scss/utilities/_text.scss */
.text-muted {
  color: #6c757d !important; }

/* line 54, node_modules/bootstrap/scss/utilities/_text.scss */
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

/* line 55, node_modules/bootstrap/scss/utilities/_text.scss */
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

/* line 59, node_modules/bootstrap/scss/utilities/_text.scss */
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

/* line 63, node_modules/bootstrap/scss/utilities/_text.scss */
.text-decoration-none {
  text-decoration: none !important; }

/* line 65, node_modules/bootstrap/scss/utilities/_text.scss */
.text-break {
  word-wrap: break-word !important; }

/* line 71, node_modules/bootstrap/scss/utilities/_text.scss */
.text-reset {
  color: inherit !important; }

/* line 7, node_modules/bootstrap/scss/utilities/_visibility.scss */
.visible {
  visibility: visible !important; }

/* line 11, node_modules/bootstrap/scss/utilities/_visibility.scss */
.invisible {
  visibility: hidden !important; }

@media print {
  /* line 13, node_modules/bootstrap/scss/_print.scss */
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  /* line 24, node_modules/bootstrap/scss/_print.scss */
  a:not(.btn) {
    text-decoration: underline; }
  /* line 34, node_modules/bootstrap/scss/_print.scss */
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /* line 49, node_modules/bootstrap/scss/_print.scss */
  pre {
    white-space: pre-wrap !important; }
  /* line 52, node_modules/bootstrap/scss/_print.scss */
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  /* line 63, node_modules/bootstrap/scss/_print.scss */
  thead {
    display: table-header-group; }
  /* line 67, node_modules/bootstrap/scss/_print.scss */
  tr,
  img {
    page-break-inside: avoid; }
  /* line 72, node_modules/bootstrap/scss/_print.scss */
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  /* line 79, node_modules/bootstrap/scss/_print.scss */
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  /* line 92, node_modules/bootstrap/scss/_print.scss */
  body {
    min-width: 992px !important; }
  /* line 95, node_modules/bootstrap/scss/_print.scss */
  .container {
    min-width: 992px !important; }
  /* line 100, node_modules/bootstrap/scss/_print.scss */
  .navbar {
    display: none; }
  /* line 103, node_modules/bootstrap/scss/_print.scss */
  .badge {
    border: 1px solid #000; }
  /* line 107, node_modules/bootstrap/scss/_print.scss */
  .table {
    border-collapse: collapse !important; }
    /* line 110, node_modules/bootstrap/scss/_print.scss */
    .table td,
    .table th {
      background-color: #fff !important; }
  /* line 117, node_modules/bootstrap/scss/_print.scss */
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E6E7E8 !important; }
  /* line 123, node_modules/bootstrap/scss/_print.scss */
  .table-dark {
    color: inherit; }
    /* line 126, node_modules/bootstrap/scss/_print.scss */
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #E6E7E8; }
  /* line 134, node_modules/bootstrap/scss/_print.scss */
  .table .thead-dark th {
    color: inherit;
    border-color: #E6E7E8; } }

/* line 7, assets/scss/base/_typography.scss */
body {
  font-family: 'Lato', sans-serif;
  color: #2B2024; }

/* line 11, assets/scss/base/_typography.scss */
* {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 17, assets/scss/base/_typography.scss */
.light {
  font-weight: 300; }

/* line 21, assets/scss/base/_typography.scss */
.normal {
  font-weight: 400; }

/* line 24, assets/scss/base/_typography.scss */
.medium {
  font-weight: 500; }

/* line 28, assets/scss/base/_typography.scss */
.semibold {
  font-weight: 600; }

/* line 32, assets/scss/base/_typography.scss */
.bold {
  font-weight: 700; }

/* line 36, assets/scss/base/_typography.scss */
.xbold {
  font-weight: 800; }

/* line 40, assets/scss/base/_typography.scss */
.xxbold {
  font-weight: 900; }

/* line 45, assets/scss/base/_typography.scss */
.display3 {
  font-style: normal;
  font-size: 64px;
  line-height: 78px; }

/* line 50, assets/scss/base/_typography.scss */
.display1 {
  font-weight: 800;
  font-size: 144px;
  line-height: 176px; }
  @media (max-width: 991.98px) {
    /* line 50, assets/scss/base/_typography.scss */
    .display1 {
      font-size: 4.75rem;
      line-height: 176px; } }

/* line 60, assets/scss/base/_typography.scss */
.p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 267.4%;
  /* or 43px */
  letter-spacing: 0.1em; }

/* line 72, assets/scss/base/_typography.scss */
.gray {
  color: #FBF9FA; }

/* line 76, assets/scss/base/_typography.scss */
.pink {
  color: #FD0054; }

/* line 80, assets/scss/base/_typography.scss */
.black {
  color: #2B2024; }

/* line 87, assets/scss/base/_typography.scss */
.pink-stripe {
  position: relative;
  font-weight: 800; }
  /* line 90, assets/scss/base/_typography.scss */
  .pink-stripe::after {
    content: "";
    position: absolute;
    width: 224.7px;
    height: 55px;
    background: #FD0054;
    right: 33px;
    top: 43px;
    z-index: -1; }
  /* line 102, assets/scss/base/_typography.scss */
  .pink-stripe.pink-stripe-two::after {
    width: 428px; }
  /* line 109, assets/scss/base/_typography.scss */
  .pink-stripe.display1::after {
    bottom: 0;
    top: unset;
    height: 77px; }

/* line 21, assets/scss/components/_buttons.scss */
.btn {
  text-transform: uppercase;
  color: #fff;
  border-radius: 0;
  border: 3px solid #2B2024;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: #2B2024;
  font-family: inherit; }
  /* line 11, assets/scss/components/_buttons.scss */
  .btn:focus {
    outline: none; }

/* line 25, assets/scss/components/_buttons.scss */
.btn-primary {
  color: white !important; }

/* line 29, assets/scss/components/_buttons.scss */
a.btn {
  color: #fff; }

/* line 33, assets/scss/components/_buttons.scss */
.form-control {
  background-color: #FBFBFB;
  border: 1px solid #E6E7E8;
  border-radius: 1px; }

/* line 40, assets/scss/components/_buttons.scss */
.btn-discreet {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
  text-transform: unset; }
  /* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
  .btn-discreet:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  /* line 18, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-discreet:focus, .btn-discreet.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  /* line 32, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-discreet.disabled, .btn-discreet:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  /* line 43, node_modules/bootstrap/scss/mixins/_buttons.scss */
  .btn-discreet:not(:disabled):not(.disabled):active, .btn-discreet:not(:disabled):not(.disabled).active,
  .show > .btn-discreet.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    /* line 53, node_modules/bootstrap/scss/mixins/_buttons.scss */
    .btn-discreet:not(:disabled):not(.disabled):active:focus, .btn-discreet:not(:disabled):not(.disabled).active:focus,
    .show > .btn-discreet.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

/* line 1, assets/scss/layout/_header.scss */
header {
  padding-bottom: 5.875rem; }
  @media (max-width: 991.98px) {
    /* line 1, assets/scss/layout/_header.scss */
    header {
      padding-bottom: 2.0625rem; } }
  @media (max-width: 767.98px) {
    /* line 1, assets/scss/layout/_header.scss */
    header {
      background: none; } }
  /* line 13, assets/scss/layout/_header.scss */
  header .navbar-wrapper {
    position: fixed;
    z-index: 6;
    width: 100%;
    top: 0; }
    /* line 18, assets/scss/layout/_header.scss */
    header .navbar-wrapper .navbar {
      padding: 1.0625rem 2.5rem;
      z-index: 6;
      transition: 0.3s ease; }
      @media (max-width: 767.98px) {
        /* line 18, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar {
          padding: 1.5rem 1rem; } }
      @media (max-width: 767.98px) {
        /* line 28, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar__brand img {
          max-width: 109px; } }
      /* line 34, assets/scss/layout/_header.scss */
      header .navbar-wrapper .navbar__brand #headerLogo {
        max-width: 90px; }
        /* line 37, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar__brand #headerLogo.white path:first-child {
          fill: white; }
        /* line 43, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar__brand #headerLogo.dark path:first-child {
          fill: #2B2024; }
      /* line 49, assets/scss/layout/_header.scss */
      header .navbar-wrapper .navbar__brand #headerLogoDark {
        display: none; }
      /* line 54, assets/scss/layout/_header.scss */
      header .navbar-wrapper .navbar .nav {
        margin-right: 4.4375rem; }
        @media screen and (max-width: 840px) {
          /* line 54, assets/scss/layout/_header.scss */
          header .navbar-wrapper .navbar .nav {
            display: none; } }
        /* line 59, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar .nav__item {
          margin-left: 28px;
          margin-right: 12px; }
          /* line 63, assets/scss/layout/_header.scss */
          header .navbar-wrapper .navbar .nav__item:first-child {
            margin-left: 0; }
          /* line 67, assets/scss/layout/_header.scss */
          header .navbar-wrapper .navbar .nav__item:last-child {
            margin-right: 0; }
        /* line 72, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar .nav__link {
          text-decoration: none;
          font-weight: 500;
          font-size: 0.875rem;
          color: #2B2024;
          text-transform: uppercase; }
      /* line 81, assets/scss/layout/_header.scss */
      header .navbar-wrapper .navbar.active {
        background: #2B2024; }
        /* line 83, assets/scss/layout/_header.scss */
        header .navbar-wrapper .navbar.active * {
          color: white; }
      /* line 89, assets/scss/layout/_header.scss */
      header .navbar-wrapper .navbar.minimize {
        padding-top: 1 rem;
        padding-bottom: 1 rem; }
  /* line 96, assets/scss/layout/_header.scss */
  header #sideMenu {
    position: fixed;
    top: 0;
    right: -35%;
    width: 35%;
    height: 100%;
    background: #2B2024;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    text-align: center;
    transition: 0.6s cubic-bezier(0.74, 0.43, 0.68, 0.88);
    visibility: hidden; }
    /* line 110, assets/scss/layout/_header.scss */
    header #sideMenu.active {
      right: 0;
      width: 100%;
      visibility: visible; }
    /* line 115, assets/scss/layout/_header.scss */
    header #sideMenu ul {
      list-style: none;
      padding: 0; }
      /* line 123, assets/scss/layout/_header.scss */
      header #sideMenu ul:hover li {
        opacity: 0.3; }
        /* line 125, assets/scss/layout/_header.scss */
        header #sideMenu ul:hover li:hover {
          opacity: 1; }
          /* line 129, assets/scss/layout/_header.scss */
          header #sideMenu ul:hover li:hover a::after {
            width: 100%; }
      /* line 138, assets/scss/layout/_header.scss */
      header #sideMenu ul li {
        padding: 1rem 0;
        transition: opacity 0.2s ease-in-out; }
        /* line 142, assets/scss/layout/_header.scss */
        header #sideMenu ul li a {
          font-size: 5rem;
          font-weight: bold;
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 5px;
          position: relative;
          -webkit-backface-visibility: hidden; }
          @media (max-width: 767.98px) {
            /* line 142, assets/scss/layout/_header.scss */
            header #sideMenu ul li a {
              font-size: 2.5rem; } }
          /* line 156, assets/scss/layout/_header.scss */
          header #sideMenu ul li a::after {
            width: 0%;
            height: 4px;
            background: #FD0054;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            overflow: hidden;
            transition: .6s ease-in-out; }
  /* line 176, assets/scss/layout/_header.scss */
  header #menuToggler {
    position: fixed;
    top: 0;
    cursor: pointer;
    z-index: 7;
    height: 40px;
    width: 40px;
    top: 26px;
    right: 30px; }
    @media (max-width: 767.98px) {
      /* line 176, assets/scss/layout/_header.scss */
      header #menuToggler {
        top: 35px; } }
    /* line 187, assets/scss/layout/_header.scss */
    header #menuToggler.active span {
      background: white; }
      /* line 189, assets/scss/layout/_header.scss */
      header #menuToggler.active span:first-child {
        transform: rotate(135deg);
        margin-bottom: -0.402rem; }
      /* line 193, assets/scss/layout/_header.scss */
      header #menuToggler.active span:last-child {
        transform: rotate(-135deg);
        margin-top: 6px;
        margin-left: 0; }
    /* line 202, assets/scss/layout/_header.scss */
    header #menuToggler.white span {
      background: white; }
    /* line 209, assets/scss/layout/_header.scss */
    header #menuToggler span {
      transition: 0.5s ease;
      display: block;
      width: 35px;
      height: 1px;
      background: white; }
      @media (min-width: 576px) {
        /* line 209, assets/scss/layout/_header.scss */
        header #menuToggler span {
          background: #2B2024; } }
      /* line 220, assets/scss/layout/_header.scss */
      header #menuToggler span:last-child {
        margin-top: 8px;
        margin-left: -10px; }

/* line 234, assets/scss/layout/_header.scss */
body:not(.page-home) header .navbar.active #headerLogo path:first-child {
  fill: white; }

/* line 1, assets/scss/layout/_footer.scss */
#footer {
  padding: 3.75rem 0.9375rem;
  background-color: #2B2024;
  color: white; }
  @media (min-width: 768px) {
    /* line 1, assets/scss/layout/_footer.scss */
    #footer {
      padding: 3.0625rem 6.6875rem; } }
  /* line 11, assets/scss/layout/_footer.scss */
  #footer a {
    color: white;
    text-decoration: none;
    transition: 0.1s ease; }
    /* line 15, assets/scss/layout/_footer.scss */
    #footer a:hover {
      color: #cccccc; }
  @media (min-width: 768px) {
    /* line 21, assets/scss/layout/_footer.scss */
    #footer .border-col {
      border-right: 0.0625rem solid white; } }
  /* line 26, assets/scss/layout/_footer.scss */
  #footer #langSwitcherWrapper {
    position: relative; }
    /* line 29, assets/scss/layout/_footer.scss */
    #footer #langSwitcherWrapper::before {
      position: absolute;
      top: 21px;
      right: 17px;
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid #FD0054; }
  /* line 44, assets/scss/layout/_footer.scss */
  #footer #langswitcher:hover {
    border: none; }
  /* line 48, assets/scss/layout/_footer.scss */
  #footer #langswitcher ul {
    list-style: none;
    max-width: 100%;
    max-height: 48px;
    overflow: hidden;
    padding: 10px;
    transition: .3s ease-out;
    font-weight: 600; }
    /* line 57, assets/scss/layout/_footer.scss */
    #footer #langswitcher ul:hover {
      max-height: unset;
      box-shadow: 2px 3px 12px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      background: #3c2d33e3; }
    /* line 69, assets/scss/layout/_footer.scss */
    #footer #langswitcher ul li {
      margin-top: 1rem;
      width: 100%;
      transition: .7s ease-out;
      padding: 0.3rem 0; }
  /* line 76, assets/scss/layout/_footer.scss */
  #footer #langswitcher li.order-0 {
    margin: 0;
    border-bottom: 1px solid white; }
  @media (min-width: 768px) {
    /* line 42, assets/scss/layout/_footer.scss */
    #footer #langswitcher {
      MARGIN-TOP: -14px; } }

/* line 93, assets/scss/layout/_footer.scss */
.page-demo iframe {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 57px;
  z-index: 3; }
  /* line 103, assets/scss/layout/_footer.scss */
  .page-demo iframe .logo a img {
    display: none; }

/* line 2, assets/scss/layout/_archive.scss */
.page-blog .wrapper {
  padding-bottom: 3rem; }
  /* line 4, assets/scss/layout/_archive.scss */
  .page-blog .wrapper .tease {
    border: 3px solid #2B2024;
    background-color: #FBF9FA;
    max-width: 350px;
    padding: .4rem;
    height: 100%;
    position: relative; }
    /* line 13, assets/scss/layout/_archive.scss */
    .page-blog .wrapper .tease img {
      width: 100%;
      height: 232px;
      object-fit: cover; }
    /* line 19, assets/scss/layout/_archive.scss */
    .page-blog .wrapper .tease-content {
      padding: 1.1rem;
      padding-bottom: 4.5rem; }
      /* line 23, assets/scss/layout/_archive.scss */
      .page-blog .wrapper .tease-content h2 {
        font-size: 1.5rem; }
      /* line 27, assets/scss/layout/_archive.scss */
      .page-blog .wrapper .tease-content .read-more {
        margin-top: 1rem;
        display: none;
        text-transform: uppercase;
        color: #fff;
        border-radius: 0;
        border: 3px solid #2B2024;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        color: #2B2024;
        font-family: inherit;
        text-align: center;
        padding: .5rem; }
        /* line 11, assets/scss/components/_buttons.scss */
        .page-blog .wrapper .tease-content .read-more:focus {
          outline: none; }
      /* line 35, assets/scss/layout/_archive.scss */
      .page-blog .wrapper .tease-content-bottom {
        transform: translate(50%, 0%);
        bottom: 1rem;
        left: 0;
        z-index: 3;
        position: absolute; }
    /* line 47, assets/scss/layout/_archive.scss */
    .page-blog .wrapper .tease-category {
      position: relative;
      margin-top: -26px; }
      /* line 52, assets/scss/layout/_archive.scss */
      .page-blog .wrapper .tease-category-inner {
        background: #FD0054;
        padding: 0.3rem;
        color: white; }

/* line 1, assets/scss/_general.scss */
html {
  scroll-behavior: smooth;
  /******DEBUG*******/ }

/* line 15, assets/scss/_general.scss */
body {
  font-family: 'Montserrat', 'sans-serif';
  overflow-x: hidden;
  background: #FBF9FA; }

/* line 21, assets/scss/_general.scss */
.pink-line {
  border-top: 0.0625rem solid #FD0054; }

/* line 25, assets/scss/_general.scss */
.bg-pink {
  background-color: #FD0054 !important; }

/* line 29, assets/scss/_general.scss */
.bg-black {
  background-color: #2B2024 !important; }

/* line 33, assets/scss/_general.scss */
.transition {
  width: 0%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  background: black; }

/* line 42, assets/scss/_general.scss */
.loading-screen {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background-color: #2B2024;
  width: 100%;
  height: 100%;
  transform: scaleY(0) skewX(0);
  transform-origin: top left; }

/* line 54, assets/scss/_general.scss */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none; }

/* line 65, assets/scss/_general.scss */
#cookie-notice {
  font-family: 'Montserrat', 'sans-serif'; }

/* line 69, assets/scss/_general.scss */
#scrollContent {
  overflow: hidden; }

/*Scrollbar*/
/* width */
/* line 77, assets/scss/_general.scss */
::-webkit-scrollbar {
  width: 6px;
  display: none; }

/* Track */
/* line 83, assets/scss/_general.scss */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e7e7e7; }

/* Handle */
/* line 89, assets/scss/_general.scss */
::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px; }

/* line 96, assets/scss/_general.scss */
#preloader {
  position: fixed;
  background: #2B2024;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  flex-direction: column; }
  /* line 111, assets/scss/_general.scss */
  #preloader svg {
    margin-bottom: 2rem;
    max-width: 100px;
    overflow: visible; }
    /* line 116, assets/scss/_general.scss */
    #preloader svg #pinkDotPath {
      transform-origin: -50px; }
  /* line 122, assets/scss/_general.scss */
  #preloader .logo-wrapper {
    position: relative; }
  /* line 126, assets/scss/_general.scss */
  #preloader .fillbar {
    width: 0%;
    border: 1px solid #FD0054;
    height: 3px;
    background: #FD0054;
    left: 39px;
    animation: fillbar-fill 2s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards; }
    /* line 137, assets/scss/_general.scss */
    #preloader .fillbar-wrapper {
      position: relative;
      width: 60%; }
  /* line 144, assets/scss/_general.scss */
  #preloader .spinner {
    width: 80px;
    height: 80px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid black;
    border-radius: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes fillbar-fill {
  from {
    width: 0%; }
  to {
    width: 100%; } }

/* line 5, assets/scss/pages/_home.scss */
.page-home header #textPath {
  fill: white; }

@media (max-width: 767.98px) {
  /* line 3, assets/scss/pages/_home.scss */
  .page-home header {
    background: #2B2024 !important; } }

/* line 23, assets/scss/pages/_home.scss */
.page-home section {
  min-height: 100vh; }

/* line 27, assets/scss/pages/_home.scss */
.page-home .graphic-rect {
  display: none;
  position: relative; }
  @media (min-width: 768px) {
    /* line 27, assets/scss/pages/_home.scss */
    .page-home .graphic-rect {
      display: block; } }
  /* line 33, assets/scss/pages/_home.scss */
  .page-home .graphic-rect--shape {
    width: 408px;
    height: 406px;
    background: #fd0054;
    box-shadow: 24px 15px 74px rgba(0, 0, 0, 0.25);
    transform: rotate(26.33deg); }
  /* line 41, assets/scss/pages/_home.scss */
  .page-home .graphic-rect--image {
    position: absolute;
    transform: scale(0.6);
    top: -32px;
    left: -62px; }

/* line 48, assets/scss/pages/_home.scss */
.page-home #bigCiricleIntro {
  position: absolute;
  top: 0;
  display: none; }
  @media (min-width: 768px) {
    /* line 48, assets/scss/pages/_home.scss */
    .page-home #bigCiricleIntro {
      display: block; } }

@keyframes blink {
  from, to {
    border-color: transparent; }
  50% {
    border-color: white; } }

/* line 64, assets/scss/pages/_home.scss */
.page-home #hero #animatedCursor {
  opacity: 0; }

@media (max-width: 767.98px) {
  /* line 68, assets/scss/pages/_home.scss */
  .page-home #hero #introTitle {
    font-size: 2.7rem;
    font-weight: 600; } }

/* line 75, assets/scss/pages/_home.scss */
.page-home #hero #introTitle.cursor-active {
  animation: blink .8s step-end infinite;
  display: inline;
  border-right: 2px solid white; }

/* line 82, assets/scss/pages/_home.scss */
.page-home #hero .graphic-rect {
  position: relative;
  display: block; }
  /* line 86, assets/scss/pages/_home.scss */
  .page-home #hero .graphic-rect--shape {
    width: 408px;
    height: 406px;
    background: #fd0054;
    box-shadow: 24px 15px 74px rgba(0, 0, 0, 0.25);
    transform: rotate(26.33deg); }
  /* line 94, assets/scss/pages/_home.scss */
  .page-home #hero .graphic-rect--image {
    position: absolute;
    transform: scale(0.6);
    top: -32px;
    left: -62px; }
  @media (max-width: 767.98px) {
    /* line 82, assets/scss/pages/_home.scss */
    .page-home #hero .graphic-rect {
      transform: scale(0.5);
      margin-left: -1.25rem; } }

/* line 108, assets/scss/pages/_home.scss */
.page-home #hero #linesGraphic {
  display: none; }
  @media (min-width: 768px) {
    /* line 108, assets/scss/pages/_home.scss */
    .page-home #hero #linesGraphic {
      display: block; } }

@media (max-width: 991.98px) {
  /* line 118, assets/scss/pages/_home.scss */
  .page-home #hero #qualitiesList h3:not(:last-child) {
    color: #fff; } }

@media (max-width: 767.98px) {
  /* line 116, assets/scss/pages/_home.scss */
  .page-home #hero #qualitiesList h3 {
    font-size: 3rem; } }

@media (max-width: 767.98px) {
  /* line 57, assets/scss/pages/_home.scss */
  .page-home #hero {
    background: #2B2024; } }

/* line 135, assets/scss/pages/_home.scss */
.page-home #develop {
  position: relative; }
  /* line 141, assets/scss/pages/_home.scss */
  .navbar_brand img {
    display: none; }
  /* line 145, assets/scss/pages/_home.scss */
  .navbar_brand #headerLogoDark {
    display: block; }
  /* line 151, assets/scss/pages/_home.scss */
  .page-home #developRect {
    margin-top: 9.6875rem; }
  /* line 158, assets/scss/pages/_home.scss */
  .page-home #develop .graphic-rect--image {
    position: absolute;
    transform: scale(0.6);
    top: -56px;
    left: -146px;
    max-width: 699px; }
  /* line 168, assets/scss/pages/_home.scss */
  .page-home #develop #title::after {
    content: url("../../dist/img/Rectangle-text-bg-pink.svg");
    position: absolute;
    right: 33px;
    top: 43px;
    z-index: -1; }
    @media (max-width: 767.98px) {
      /* line 168, assets/scss/pages/_home.scss */
      .page-home #develop #title::after {
        right: 0;
        top: 64px;
        width: 50%; } }
  /* line 182, assets/scss/pages/_home.scss */
  .page-home #developRectDots {
    position: absolute;
    display: none;
    z-index: -1;
    left: 477px;
    bottom: 175px; }
    @media (min-width: 768px) {
      /* line 182, assets/scss/pages/_home.scss */
      .page-home #developRectDots {
        display: block; } }

/* line 197, assets/scss/pages/_home.scss */
.page-home #design #title {
  overflow: hidden; }
  /* line 199, assets/scss/pages/_home.scss */
  .page-home #design #title::after {
    content: url("../../dist/img/Rectangle-text-bg-pink.svg");
    position: absolute;
    right: 33px;
    top: 43px;
    z-index: -1; }
    @media (max-width: 767.98px) {
      /* line 199, assets/scss/pages/_home.scss */
      .page-home #design #title::after {
        right: 0;
        top: 64px;
        width: 50%; } }

/* line 213, assets/scss/pages/_home.scss */
.page-home #designGraphic {
  position: relative; }
  @media (max-width: 991.98px) {
    /* line 213, assets/scss/pages/_home.scss */
    .page-home #designGraphic {
      display: none; } }
  /* line 218, assets/scss/pages/_home.scss */
  .page-home #designGraphicRect {
    position: absolute;
    width: 13rem;
    height: 28.1875rem;
    background: #FD0054;
    transform: rotate(-64.6deg);
    z-index: -3;
    top: 21rem;
    left: 9rem; }
  /* line 229, assets/scss/pages/_home.scss */
  .page-home #designGraphicCircle {
    width: 20.5rem;
    position: absolute;
    height: 20.5rem;
    border-radius: 50%;
    background: #2B2024;
    z-index: -3;
    top: 8.37rem;
    right: 3rem;
    top: -8rem;
    right: 0; }
  /* line 242, assets/scss/pages/_home.scss */
  .page-home #designGraphicLines {
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 3.125rem;
    top: -63px;
    transform: rotate(45deg); }
    /* line 253, assets/scss/pages/_home.scss */
    .page-home #designGraphicLines hr:first-child {
      margin-bottom: 30px; }
    /* line 258, assets/scss/pages/_home.scss */
    .page-home #designGraphicLines hr:nth-child(2) {
      margin-right: -6rem;
      max-width: 100%;
      margin-top: 4rem; }
  /* line 265, assets/scss/pages/_home.scss */
  .page-home #designGraphicScreenshot1 {
    top: 0;
    z-index: 0;
    position: absolute; }
  /* line 271, assets/scss/pages/_home.scss */
  .page-home #designGraphicScreenshot2 {
    top: 4.375rem;
    z-index: 0;
    position: absolute;
    right: 1rem; }

/* line 283, assets/scss/pages/_home.scss */
.page-home #works .work {
  display: flex; }
  @media (max-width: 991.98px) {
    /* line 283, assets/scss/pages/_home.scss */
    .page-home #works .work {
      margin-bottom: 4rem; } }
  /* line 288, assets/scss/pages/_home.scss */
  .page-home #works .work__image {
    overflow: hidden;
    border: 0.1875rem solid #2B2024; }
    /* line 292, assets/scss/pages/_home.scss */
    .page-home #works .work__image img {
      transition: .7s ease-in-out;
      max-width: 100%;
      height: 28.125rem;
      width: 300px;
      object-fit: cover; }
      @media (min-width: 768px) {
        /* line 292, assets/scss/pages/_home.scss */
        .page-home #works .work__image img {
          height: 39.8125rem; } }
  /* line 307, assets/scss/pages/_home.scss */
  .page-home #works .work__title {
    margin-top: 1.3125rem;
    text-align: center; }
    /* line 310, assets/scss/pages/_home.scss */
    .page-home #works .work__title a {
      text-decoration: none; }
      /* line 312, assets/scss/pages/_home.scss */
      .page-home #works .work__title a h5 {
        position: relative;
        display: inline-block;
        padding-bottom: 0.4rem; }
        /* line 316, assets/scss/pages/_home.scss */
        .page-home #works .work__title a h5::after {
          width: 0%;
          height: 4px;
          background: #FD0054;
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          overflow: hidden;
          transition: .6s ease-in-out; }
  /* line 334, assets/scss/pages/_home.scss */
  .page-home #works .work__wrapper {
    margin: auto; }
    @media (min-width: 768px) {
      /* line 334, assets/scss/pages/_home.scss */
      .page-home #works .work__wrapper {
        margin: unset; } }
    /* line 340, assets/scss/pages/_home.scss */
    .page-home #works .work__wrapper:hover a {
      text-decoration: none; }
      /* line 343, assets/scss/pages/_home.scss */
      .page-home #works .work__wrapper:hover a h5::after {
        width: 100%; }
    /* line 351, assets/scss/pages/_home.scss */
    .page-home #works .work__wrapper:hover img {
      transform: scale(1.05); }

/* line 360, assets/scss/pages/_home.scss */
.page-home #works .graphic-divider {
  max-height: 40rem;
  display: flex;
  text-align: center; }
  @media (min-width: 768px) {
    /* line 360, assets/scss/pages/_home.scss */
    .page-home #works .graphic-divider {
      width: 13.6875rem; } }
  /* line 370, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider svg {
    display: none; }
    @media (min-width: 768px) {
      /* line 370, assets/scss/pages/_home.scss */
      .page-home #works .graphic-divider svg {
        display: block;
        position: absolute;
        z-index: -1; } }
  /* line 379, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider__line {
    height: 100%;
    border-right: 0.0625rem solid #FD0054;
    width: 0;
    margin: auto; }
  /* line 387, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider #graphic1 {
    margin-top: 3rem;
    margin-left: -7.3rem;
    animation-iteration-count: infinite; }
  /* line 395, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider #graphic2 {
    bottom: 18rem;
    margin-left: 6.7rem; }
  /* line 400, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider #graphic3 {
    margin-top: 3rem;
    margin-left: -1.5rem; }
  /* line 405, assets/scss/pages/_home.scss */
  .page-home #works .graphic-divider #graphic4 {
    margin-left: 6.9rem;
    bottom: 3rem; }

@keyframes grafike__y__1 {
  from {
    margin-top: 3rem; }
  to {
    margin-top: 8rem; } }

@keyframes grafike__y__2 {
  from {
    margin-top: 8rem; }
  to {
    margin-top: 3rem; } }

@media (min-width: 576px) {
  /* line 437, assets/scss/pages/_home.scss */
  .page-home #smmImage {
    position: relative;
    border-radius: 50%;
    background: #eaeaea;
    overflow: hidden;
    max-width: 585px; }
    /* line 446, assets/scss/pages/_home.scss */
    .page-home #smmImage::after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; } }

/* line 459, assets/scss/pages/_home.scss */
.page-home #smmImage img {
  object-fit: cover;
  width: 585px;
  height: 585px; }

@media (max-width: 767.98px) {
  /* line 466, assets/scss/pages/_home.scss */
  .page-home #smm h3 {
    line-height: unset; } }

/* line 478, assets/scss/pages/_home.scss */
.page-home #cta h2 {
  font-size: 5rem;
  text-transform: lowercase;
  letter-spacing: 2px; }
  @media (max-width: 767.98px) {
    /* line 478, assets/scss/pages/_home.scss */
    .page-home #cta h2 {
      font-size: 3rem; } }

/* line 486, assets/scss/pages/_home.scss */
.page-home #cta .btn-smart {
  display: flex;
  position: relative;
  height: 150px;
  width: 250px;
  justify-content: center;
  align-items: center;
  margin: auto; }
  @media (min-width: 576px) {
    /* line 488, assets/scss/pages/_home.scss */
    .page-home #cta .btn-smart__wrapper {
      display: flex;
      justify-content: space-evenly;
      max-width: 590px;
      margin: auto; } }
  @media (min-width: 576px) {
    /* line 486, assets/scss/pages/_home.scss */
    .page-home #cta .btn-smart {
      margin: 0; } }
  /* line 510, assets/scss/pages/_home.scss */
  .page-home #cta .btn-smart__link {
    display: contents;
    text-decoration: none; }
  /* line 516, assets/scss/pages/_home.scss */
  .page-home #cta .btn-smart__rect {
    position: absolute;
    height: 50px;
    width: 250px;
    border: solid 3px #2B2024;
    border-radius: 0; }
  /* line 525, assets/scss/pages/_home.scss */
  .page-home #cta .btn-smart__button {
    letter-spacing: 2px; }

/* line 2, assets/scss/pages/_single-project.scss */
.page-projekt #project {
  position: relative; }

/* line 5, assets/scss/pages/_single-project.scss */
.page-projekt #shape {
  width: 269px;
  height: 269px;
  background: #FD0054;
  opacity: 0.5;
  position: absolute;
  top: -3rem;
  left: -9rem;
  border-radius: 50%;
  z-index: 1; }

/* line 17, assets/scss/pages/_single-project.scss */
.page-projekt #projectTitle {
  position: absolute;
  left: 10%;
  top: 30%;
  max-width: 100%;
  z-index: -1; }
  /* line 25, assets/scss/pages/_single-project.scss */
  .page-projekt #projectTitle span {
    color: white;
    text-shadow: -1px 0 rgba(0, 0, 0, 0.19), 0 1px rgba(0, 0, 0, 0.19), 1px 0 rgba(0, 0, 0, 0.19), 0 -1px rgba(0, 0, 0, 0.19);
    font-weight: bold;
    font-size: 18vw;
    line-height: 224px;
    white-space: nowrap; }

/* line 2, assets/scss/pages/_about.scss */
.page-about #project {
  position: relative; }

/* line 6, assets/scss/pages/_about.scss */
.page-about #shape {
  width: 269px;
  height: 269px;
  background: #FD0054;
  opacity: 0.5;
  position: absolute;
  top: -3rem;
  left: -9rem;
  border-radius: 50%;
  z-index: 1; }

@media (min-width: 768px) {
  /* line 18, assets/scss/pages/_about.scss */
  .page-about #title {
    padding-left: 1.7rem; } }

/* line 23, assets/scss/pages/_about.scss */
.page-about #title::after {
  content: url("../../dist/img/Rectangle-text-bg-pink.svg");
  position: absolute;
  left: 33px;
  top: 58px;
  z-index: -1;
  width: 150px;
  transform: scale(0.5); }
  @media (min-width: 768px) {
    /* line 23, assets/scss/pages/_about.scss */
    .page-about #title::after {
      content: url("../../dist/img/Rectangle-text-bg-pink.svg");
      position: absolute;
      left: 123px;
      top: 72px;
      z-index: -1;
      transform: scale(0.9); } }

/* line 44, assets/scss/pages/_about.scss */
.page-about #projectTitle {
  position: absolute;
  left: 10%;
  top: 30%;
  max-width: 100%;
  z-index: -1; }
  /* line 52, assets/scss/pages/_about.scss */
  .page-about #projectTitle span {
    color: white;
    text-shadow: -1px 0 rgba(0, 0, 0, 0.19), 0 1px rgba(0, 0, 0, 0.19), 1px 0 rgba(0, 0, 0, 0.19), 0 -1px rgba(0, 0, 0, 0.19);
    font-weight: bold;
    font-size: 184px;
    line-height: 224px;
    white-space: nowrap; }
    @media (max-width: 991.98px) {
      /* line 52, assets/scss/pages/_about.scss */
      .page-about #projectTitle span {
        font-size: unset; } }

/* line 67, assets/scss/pages/_about.scss */
.page-about .teamcol {
  text-align: center; }
  /* line 71, assets/scss/pages/_about.scss */
  .page-about .teamcol img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 4px solid #2B2024; }
  @media (max-width: 991.98px) {
    /* line 67, assets/scss/pages/_about.scss */
    .page-about .teamcol {
      padding: 0 2.4rem; } }
  /* line 83, assets/scss/pages/_about.scss */
  .page-about .teamcol p {
    font-size: 1.2rem; }
    @media (max-width: 991.98px) {
      /* line 83, assets/scss/pages/_about.scss */
      .page-about .teamcol p {
        text-align: center; } }
    /* line 90, assets/scss/pages/_about.scss */
    .page-about .teamcol p:first-of-type {
      margin-top: 2rem;
      margin-bottom: 0; }
  /* line 96, assets/scss/pages/_about.scss */
  .page-about .teamcol:last-child {
    padding-bottom: 4rem; }

/* line 7, assets/scss/posts/_single.scss */
.post-type-post a {
  color: #FD0054; }
