#footer {

    padding: rem(60) rem(15);

    @include media-breakpoint-up(md) {
        padding: rem(49) rem(107);

    }
    background-color: $brand-black;
    color: white;
    a {
        color: white;
        text-decoration: none;
        transition: 0.1s ease;
        &:hover {
            color: darken($color: #fff, $amount: 20)
        }
    }

    @include media-breakpoint-up(md) {
        .border-col {
            border-right: 0.0625rem solid white;
        }
    }

    #langSwitcherWrapper {
        position:relative;

                &::before {
                    position: absolute;
                    top: 21px;
                    right: 17px;
                    content: "";
                    width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid $brand-pink;
                }
            }

    #langswitcher {

                 &:hover{
                    border: none;
                }

        ul{
                list-style: none;
                max-width: 100%;
                max-height: 48px;
                overflow: hidden;
                padding: 10px;
                transition: .3s ease-out;
                font-weight: 600;

            &:hover{
                max-height: unset;
                    box-shadow: 2px 3px 12px 3px rgba(0,0,0,0.2);
                border-radius: 5px;
                background: #3c2d33e3;


                & li.order-0 {
                }

            }

            li {
                margin-top: 1rem;
                    width: 100%;
    transition: .7s ease-out;
    padding: 0.3rem 0;
}
            }
             & li.order-0 {
                margin: 0;
                border-bottom: 1px solid white;
                }



        
    @include media-breakpoint-up(md) {
        MARGIN-TOP: -14px;
    }

    }

    
}
.page-demo {
  iframe {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 57px;
    z-index: 3;
    .logo {
        a{
            img {
                display: none;
            }
        }
    }
}  
}
