.page-about {
    #project {
        position: relative;
    }

    #shape {
        width: 269px;
        height: 269px;
        background: $brand-pink;
        opacity: 0.5;
        position: absolute;
        top: -3rem;
        left: -9rem;
        border-radius: 50%;
        z-index: 1;
    }

    #title {
                           @include media-breakpoint-up(md) {

        padding-left: 1.7rem;
    }
            &::after {

                content: url("../../dist/img/Rectangle-text-bg-pink.svg");
                position: absolute;
                left: 33px;
                top: 58px;
                z-index: -1;
                width: 150px;
                    transform: scale(0.5);

                   @include media-breakpoint-up(md) {
content: url("../../dist/img/Rectangle-text-bg-pink.svg");
                position: absolute;
                left: 123px;
                top: 72px;
                z-index: -1;  
                    transform: scale(0.9);
                              }
            }
        }

    #projectTitle {
        position: absolute;
       
        left: 10%;
        top: 30%;
        max-width: 100%;
        z-index: -1;
       
        span {
            color: rgba(255,255,255,1);
            $colr: rgba(0, 0, 0, 0.19);
            text-shadow: -1px 0 $colr, 0 1px $colr, 1px 0 $colr, 0 -1px $colr;
            font-weight: bold;
            font-size: 184px;
                 @include media-breakpoint-down(md) {
            font-size: unset;

    }
            line-height: 224px;
            white-space: nowrap;
        }
    }

    .teamcol {

        text-align: center;

        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
            border: 4px solid $brand-black;

        }

        @include media-breakpoint-down(md) {
            padding: 0 2.4rem; 
            }

        p {
            font-size: 1.2rem;
            @include media-breakpoint-down(md) {
                text-align: center;

            }

            &:first-of-type {
    margin-top: 2rem;
    margin-bottom: 0;
}
        }

        &:last-child {
            padding-bottom:4rem;
        }
    }

    
}